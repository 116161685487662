import { call } from "redux-saga/effects";

export function* mensajeDialogflow(companyId, idConv, msge, param_Hijo) {
  try {
    let clienteBody = {
      param_Hijo,
      idCompany: companyId,
      idConv: idConv,
      msge: msge
    };
    const urlConv = process.env.REACT_APP_URL_DIALOGFLOW;
    const response = yield call(fetch, urlConv, {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
        'accept': "application/json, text/plain, */*",
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'cache-control': 'no-cache',
      },
      body: JSON.stringify(clienteBody)
    });

    // if (response.status >= 200 && response.status < 300) {
    //   const successResponse = yield call([response, response.json]);
    //   console.log('successResponse', successResponse);
    //   return successResponse;
    // } else {
    //   const errorResponse = yield call([response, response.json]);
    //   console.error('errorResponse', errorResponse);
    // }
  
    

  } catch (err) {
    console.error('DialogFlow.mensajeDialogflow:', err);
    throw err
  }
}


