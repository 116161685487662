import { getFromSession } from "./session";
import { firebaseDatabase } from "./firebase";


export function* consultaProperty(company, prop) {
	/* console.log('entro a consultarProperty******'); */
	try {
		let resp = false;
		if (company) { //Se consulta por company
			yield firebaseDatabase.collection(`company/${company}/configuration`).doc(prop).get().then(
				(snapshot) => {
					if (snapshot.exists) {
						resp = snapshot.data().value;
					};
				});
		} else {//Se consulta configuracion general
			yield firebaseDatabase.collection(`configuration`).doc(prop).get().then(
				(snapshot) => {
					if (snapshot.exists) {
						resp = snapshot.data().value;
					};
				});
		}
		/* console.log('final de eso', resp); */
		return resp;
	} catch (err) {
		console.error('configuration.consultarProperty :' + err);
		throw err;
	}
}

export function* consultaPropertyAll(company, prop) {
	//PARA CONSULTAR TODOS LOS VALORES EN EL DOCUMENTO NO SOLAMENTE EL VALUE
	try {
		let resp = false;

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc(prop).get().then(
			(snapshot) => {
				if (snapshot.exists) {
					resp = snapshot.data();
				};
			});

		return resp;
	} catch (err) {
		console.error('configuration.consultarProperty :' + err);
		throw err;
	}
}


export function* validarHorarioHabil(company) {

	var config = yield consultaProperty(company, 'HORARIO_HABIL');
	var fechaActual = new Date();
	var weekday = new Array(7);
	weekday[0] = "DOMINGO";
	weekday[1] = "LUNES";
	weekday[2] = "MARTES";
	weekday[3] = "MIERCOLES";
	weekday[4] = "JUEVES";
	weekday[5] = "VIERNES";
	weekday[6] = "SABADO";
	var day = weekday[fechaActual.getDay()];

	var fec = fechaActual.getFullYear() + "-" + (fechaActual.getMonth() + 1) + "-" + fechaActual.getDate()
	//1. verificar fecha exacta:
	if (config[fec]) {
		return { valido: yield checkTime(config[fec]), textoHorario: stringifyHorarioHabil(config) };
	} else if (config[day]) {
		return { valido: yield checkTime(config[day]), textoHorario: stringifyHorarioHabil(config) };
	} else {
		//miro si es dia de semana o fin de semana:
		if (fechaActual.getDay() === 0 || fechaActual.getDay() === 6) { //0 domingo, 6 sabado
			return { valido: yield checkTime(config["WEEKEND_HOURS"]), textoHorario: stringifyHorarioHabil(config) };
		} else {
			return { valido: yield checkTime(config["REG_HOURS"]), textoHorario: stringifyHorarioHabil(config) };
		}
	}
}

/**
*Funcion que valida si la hora actual que se tiene, esta dentro de un rango recibido.
* el rango se recibe como un json: {ini:"hh:mm", fin:"hh:mm"}
*/
function* checkTime(rango) {
	var fechaActual = new Date();
	var hora = fechaActual.getHours();
	var min = fechaActual.getMinutes();
	var ini = rango.ini.split(":");
	var a = parseInt(ini[0]);
	var b = parseInt(ini[1]);
	var fin = rango.fin.split(":");
	var c = parseInt(fin[0]);
	var d = parseInt(fin[1]);

	if (a > c || ((a == c) && (b > d))) {
		// not a valid input
		return false;
	} else {
		if (hora > a && hora < c) {
			return true;
		} else if (hora == a && min >= b) {
			return true;
		} else if (hora == c && min <= d) {
			return true;
		} else {
			return false;
		}
	}
}

function stringifyHorarioHabil(config) {
	try {
		//console.log('config HORARIO HABIL', config);
		let resp = `Por favor tener presente que el horario de atención de nuestros asesores es 
				de lunes a viernes de ${config["REG_HOURS"].ini} a ${config["REG_HOURS"].fin}`;
		if (config["WEEKEND_HOURS"].ini != "00:00" && config["WEEKEND_HOURS"].fin != "00:00"
			&& !config["DOMINGO"] && !config["SABADO"]) {
			resp += ` y sabados, domingos y festivos de
				${config["WEEKEND_HOURS"].ini} a ${config["WEEKEND_HOURS"].fin}`;
		} else if (config["WEEKEND_HOURS"].ini != "00:00" && config["WEEKEND_HOURS"].fin != "00:00" &&
			config["DOMINGO"] && config["DOMINGO"].ini == "00:00") {
			resp += `, sábados de
				${config["WEEKEND_HOURS"].ini} a ${config["WEEKEND_HOURS"].fin}, domingos y festivos
				no se presta el servicio.`
		}

		return resp;
	} catch (error) {
		console.error('configuration.stringifyHorarioHabil', error);
		throw error;
	}

}

export function* dialogflowAccount(company) {
	var resp = false;
	var prop = 'DIALOGFLOW_ACCOUNT';
	resp = yield consultaProperty(company, prop);
	return JSON.parse(resp);
}

export function* dialogflowProjectId(company) {
	var resp = false;
	var prop = 'DIALOGFLOW_PROJECT';
	resp = yield consultaProperty(company, prop);
	if (!resp) resp = 'chat-asesores-3';
	return resp;
}
