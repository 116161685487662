import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './form.css';
import CustomReduxForm from './customReduxForm';
import CustomButton from '../custombutton/button';
import CircularProgress from "@material-ui/core/CircularProgress";




export default class CustomForm extends Component {


    render() {
        const { items, formTitle, formSubtitle, className, formName, values, buttons, updateAction, card, submitted, loading } = this.props;

        let spinner = <CircularProgress color="secondary"></CircularProgress>;
        return (
            card ?
                <Card key={`card-${formName}`} className={`card-${className}`}>
                    <Card.Header>
                        {formTitle}
                    </Card.Header>
                    <Card.Body>
                        <p className="font-bold">{formSubtitle}</p>
                        <CustomReduxForm submitted={submitted} items={items} formName={formName} dataObject={values} updateAction={updateAction} />
                        <CustomButton items={buttons} />
                    </Card.Body>
                </Card>
                : <React.Fragment>
                    <h2 className="form-title">
                        {formTitle}
                    </h2>
                    


                    <p className="font-bold subtitleStyle">{formSubtitle}</p>
                    <CustomReduxForm submitted={submitted} key={formName} className={`form-${className}`} items={items} formName={formName} dataObject={values} updateAction={updateAction} />
                    <CustomButton items={buttons} />
                </React.Fragment>
        );
    }
}
