import React, { Component } from 'react';
import ReactWebMediaPlayer from 'react-web-media-player';

export default class CustomVideoPlayer extends Component {
    render() {
        const {className, video, title} = this.props;
        return (
            <div className={className}>
                <ReactWebMediaPlayer
                    title={title}
                    video={video}
                />
            </div>
        );
    }
}
