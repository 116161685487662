import React, { Component } from 'react';
import { ITEMS, ITEM_TITLE, getItemLogout, getUserItem } from './constants';

import './sidebar.css';
import NavItem from './navitem';
import Nav from 'react-bootstrap/Nav';

import { logout } from '../../controller/session';

export default class SideNav extends Component {
    constructor(props) {
        super(props);

        const {items, onLogout} = this.props;

        this.state = {
            activePath: '/',
            items: items,
            onLogout: onLogout
        }
    }

    onItemClick = (path) => {
        this.setState({ activePath: path });
    }

    onLogoutClick = () => {
        logout();
        this.setState({ activePath: '/' });
    }

    render() {
        const { items, onLogout } = this.state;

        return (
            <div className='side-nav-wrapper'>
            <Nav className="side-nav-nar" activeKey="1" onSelect={this.onItemClick} as="ul">
                <NavItem
                    items={ITEM_TITLE}
                />
                <NavItem
                    items={items}
                    onItemClick={() => this.onItemClick}
                />
                <NavItem
                    items={getItemLogout()}
                    onItemClick={() => this.onLogoutClick()}
                />
            </Nav>
            </div>
        );
    }
}
