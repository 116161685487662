import HomePage from './pages/home';
import AuthPage from './pages/authentication';

import { 
    faHome, 
    faUsers,
} from "@fortawesome/free-solid-svg-icons";

export const GET_USERS_API = 'users/';
export const GET_USER_API = 'users/2';
export const PATH_API = 'https://reqres.in/api/';
export const COLLECTION_USER_NAME = 'users'; 
export const paths = [
    { path: '/home', component: HomePage },
    { path: '/', component: AuthPage },
];

export const menuItems = [ 
    {
        path: '/home',
        name: 'Home',
        css: faHome,
        key: 1,
        label: 'Home'
    },
    {
        path: '/auth',
        name: 'User',
        css: faUsers,
        key: 2,
        label: 'Usuarios',
    }
]

export const main = AuthPage;