import { getFromSession } from "./session";
import { firebaseDatabase } from "./firebase";


export function* consultarEmpresa(company){
    try {
        //console.log('company', company)
    let resp = false;
    yield firebaseDatabase.collection('company').where('cuenta','==', company).get().then( docs=> {
        docs.forEach(doc=>{
            resp = doc;
        });
    });
    return resp
} catch (error) {
    console.log('company.consultarEmpresa', error)
    throw error;        
}

}

