import { createStructuredSelector } from 'reselect';
import { formValueSelector, getFormValues, } from 'redux-form' // ES6;

export const getResponseMessage = (state) => state.localReducer.responseMessage;
export const getIdConversation = (state) => state.localReducer.idConversation;
export const getReduxCompanyForm = (state) => getFormValues("chatReduxForm")(state);
export const getSelectedCompanyForm = (state) => state.localReducer.selectedCompanyForm;
export const esperandoConv = (state) => state.localReducer.esperandoConv;
export const getConversationData = (state) => state.localReducer.dataConversation;
export const getNewChat = (state) =>state.localReducer.flagNewChat;
export const getCompany = (state) =>state.localReducer.company;
export const getCompanyId = (state) =>state.localReducer.company_id;
export const getHorario = (state) =>state.localReducer.horario;
export const getPlaySound = (state) => state.localReducer.playSound;
export const getInfoEncuesta  =(state) => state.localReducer.infoEncuesta;
export const getInfoFormClient = (state) =>state.localReducer.infoFormClient;
export const enableFormClient = (state) => state.localReducer.enableFormClient;
export const showWidget = (state) => state.localReducer.showWidget;
export const getUploading = (state) =>state.localReducer.uploading;
export const getStartWidget = (state) => state.localReducer.startWidget;
export const creandoConversacion = (state) => state.localReducer.creandoConversacion;
export const isEnabledCaptchaValidation = (state) =>state.localReducer.isEnabledCaptchaValidation;
export const sendingFlow = (state) =>state.localReducer.sendingFlow;
export const iniciaChatAbierto =(state) =>state.localReducer.iniciaChatAbierto;
export const chatOpened = (state) => state.localReducer.chatOpened;
export const whatsAppMobileWeb = (state) =>state.localReducer.whatsAppMobileWeb;
export const numWhatsApp = (state) =>state.localReducer.numWhatsApp;
export const authSelector = createStructuredSelector({
    responseMessage: getResponseMessage,
    idConversation: getIdConversation,
});