import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import "./style.css";
import ComponentAuth from './component';

import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import { AuthProvider } from '../../components/authprovider/auth';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    localReducer: reducer,
    form: formReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);

export default class AuthPage extends Component {
    render() {
        const { containerData } = this.props;
        return (
            <AuthProvider>
                <Provider store={store}>
                    <Container className={`main-wrapper auth chatbot-avanti-${containerData.company}`} >
                        <ComponentAuth containerData={containerData} />
                    </Container>
                </Provider>
            </AuthProvider>
        );
    }
}
