import React, { Component } from "react";
import "./navbar.css";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SideBar from "../sidebar/sidebar";
import AuthPage from "../../pages/authentication";

class CustomNavBar extends Component {
  render() {
    const { items, menuItems, main, nomatch, containerData } = this.props;
//console.log('container data en navbar.js', containerData)
    return (
      <React.Fragment>
        <AuthPage containerData={ containerData }/>
        {/*<Router>
            <Switch>
              <Route exact path="/" >
                <AuthPage containerData={ containerData }/>
              </Route>
              <Route exact path="/index.html" >
                <AuthPage containerData={ containerData }/>
              </Route>
              {
                items.map((item,i) => {
                  return <Route key={i} path={item.path} component={item.component} />
                })
              }
              <Route component={nomatch} />
            </Switch>
            </Router>*/}
      </React.Fragment>
    );
  }
}

export default CustomNavBar;