import React, { Component } from 'react';
import { Input, Select, Switch, MenuItem, TextField, Button } from '@material-ui/core';
import { VALIDATE_FIELD } from './constant';
import { connect } from "react-redux";
/* import { SelectButton } from 'primereact/selectbutton'; */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import './form.css';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
/* import { Rating } from 'primereact/rating'; */
import Rating from '@mui/material/Rating';






class CustomField extends Component {

    constructor(props) {
        super(props);
        this.changeInput = React.createRef();

        this.state = {
            fieldValue: props.value || '',
            fieldName: props.name || '',

            submitted: false
        }

    }

    componentDidUpdate(nextProps) {
        if (nextProps.fieldValue) {
            this.setState({
                fieldValue: nextProps.fieldValue,
                fieldName: nextProps.fieldName
            });
        }
    };

    onChange = (e) => {
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    handleChange = (e) => {
        /* console.log('e', e.target.value, e.target.name); */
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    onSelectionChange = (e) => {
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    onBlur = (e) => {
        const { fieldValue, fieldName } = this.state;
        const flagSubmit = this.props.submitted ? true : false;

        if (!flagSubmit) {
            this.props.updateAction({
                fieldValue,
                fieldName
            })
        }
    };

    onToggleChange = (e) => {
        let targetValue = e.target.value;
        const targetName = e.target.name;

        targetValue = typeof targetValue === "boolean" ? targetValue : targetValue === "true";

        this.setState({
            fieldValue: !targetValue,
            fieldName: targetName
        })

        this.props.updateAction({
            fieldValue: !targetValue,
            fieldName: targetName
        })
    }

    onFilterValue = (e) => {
        const { items } = this.props;
        const targetValue = e.target.value;
        const targetName = e.target.name;

        this.setState({
            fieldValue: targetValue,
            fieldName: targetName
        })

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: targetName,
            items: items
        })
    }

    selectInput = (type, typeForm, options, value, label, placeholder, name, key, disable, submitted, stars, cancel) => {
        let formControl = false;
        const customKey = `input-${label}-${key}`;

        if (type === "toggle" || typeForm == "radioButton" && typeof value !== "boolean") {
            value = value === "true";
        }

        if (typeForm == "ratingStars") {
            value = value === 1;

        }


        if (submitted) {
            value = '';
        }


        switch (typeForm) {
            case "filter":
                formControl =
                    <TextField
                        className={"custom-input"}
                        key={customKey}
                        name={name}
                        label={placeholder}
                        value={value}
                        type={type}
                        onChange={this.onFilterValue}
                        disabled={disable}
                    />;
                break;
            case "area":
                formControl =
                    <TextField
                        key={customKey}
                        className="custom-input area-text"
                        name={name}
                        multiline
                        rows={4}
                        as="textarea"
                        label={label}
                        type={type}
                        value={value}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                    />;
                break;
            /*   case "selections":
                  formControl =
                      <SelectButton
                          size="medium"
                          checked={value}
                          name={name}
                          value={value}
                          label={placeholder}
                          optionValue="code"
                          options={options}
                          onChange={this.onSelectionChange}
                          disabled={disable}
                      >
                          <i className="pi pi-times"></i>
                      </SelectButton>;
  
                
                  break; */
            case "radioButton":
                formControl = <div className="center" style={{ width: "100%" }} >
                    <div className="radioButtonLabel">{label}</div>
                    <RadioGroup
                        aria-label={name}
                        name={name}
                        value={this.state.fieldValue}
                        onChange={this.handleChange}
                    >
                        {
                            options.map((option, m) => {
                                return (
                                    <FormControlLabel className='PrivateSwitchBase-root-1' key={m} value={option.value} control={<Radio />} label={option.name} />
                                )
                            })
                        }
                    </RadioGroup>
                </div>

                break;

            case "ratingStars":
                formControl =
                    <div className="center" style={{ width: "100%" }} >
                        <div className="ratingStarsLabel">{label}</div>
                        {/* <Rating className="center" name={name} stars={stars} value={this.state.fieldValue} cancel={cancel} onChange={this.handleChange} /> */}
                        <Rating style={{ fontSize: "2rem" }} name={name} defaultValue={2} max={stars} value={this.state.fieldValue} onChange={this.handleChange} />
                    </div>

                break;

            case "toggle":
                formControl =
                    <Switch
                        size="medium"
                        checked={value}
                        name={name}
                        value={value}
                        label={placeholder}
                        onChange={this.onToggleChange}
                        disabled={disable}
                    />;
                return formControl;
            case "select":
                formControl =
                    <Select
                        name={name}
                        key={customKey}
                        as="select"
                        className="custom-input custom-select mr-sm-2"
                        id="inlineFormCustomSelect"
                        value={value}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        ref={this.changeInput}
                        disabled={disable}
                    >
                        {options.map((type, i) => {
                            return (
                                <MenuItem
                                    key={`option-${i}`}
                                    value={type.value}
                                    id={`option-${i}`}>
                                    {type.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                break;
            case "file":
                formControl =
                    <div className="file-container">
                        <label htmlFor="contained-button-file">
                            <Button className="button-file" variant="contained" color="primary" component="span">
                                {placeholder}
                            </Button>
                        </label>
                        <input
                            className="custom-file"
                            id="contained-button-file"
                            name={name}
                            value={value}
                            multiple
                            type="file"
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                        />
                    </div>
            case "multiple":

                break;
            default:
                formControl =
                    <TextField
                        className="custom-input"
                        key={customKey}
                        name={name}
                        label={placeholder}
                        value={value}
                        type={type}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                    />;
                break;
        }

        return formControl;
    }

    render() {
        const {
            placeholder,
            label,
            type,
            typeForm,
            options,
            key,
            name,
            disable,
            className,
            submitted,
            stars,
            cancel
        } = this.props;

        const customKey = label ? label.replace(/\s/g, "").toLowerCase() : "";
        const { fieldValue } = this.state;


        return (
            <div key={customKey} className={`form-custom-group ${className}`}>


                <label className="label-name">
                    <span className="content-name">{label}</span>
                </label>
                {this.selectInput(type, typeForm, options, fieldValue, label, placeholder, name, key, disable, submitted, stars, cancel)}

            </div >
        );
    }
}


const mapDispachToProps = (dispatch) => {
    return {
        updateFields: (value) => dispatch({ type: VALIDATE_FIELD, value }),
    };
};

export default connect(
    mapDispachToProps
)(CustomField);