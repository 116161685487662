import React, { Component } from 'react';
import { Card, Image } from 'react-bootstrap';
import './form.css';
import MaterialUiForm from './form';
import { Button, ButtonGroup, Switch } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from 'redux'
import { reduxForm } from 'redux-form';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ReactHtmlParser from 'react-html-parser';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';

class CustomReduxForm extends Component {
    render() {
        const {
            items,
            formTitle,
            className,
            formName,
            buttons,
            handleSubmit,
            card,
            formSubTitle,
            submitable,
            validations,
            invalid,
            pristine,
            reset,
            submitting,
            dynamicOptions,
            customHeader
        } = this.props;

        return (
            card ?
                <Card key={`card-${formName}`} className={`card-${className}`}>
                    <Card.Header>
                        {
                            customHeader ?
                                <Image className='imga-title' src={customHeader}></Image> :
                                formTitle
                        }
                        <p>{ReactHtmlParser(formSubTitle)}</p>
                    </Card.Header>
                    <Card.Body>
                        <form tabIndex={0} className={`form_container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={['enter']}
                                onKeyEvent={handleSubmit}>
                                <MaterialUiForm
                                    submitable={submitable}
                                    items={items}
                                    validations={validations}
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit}
                                    buttons={buttons}
                                    dynamicOptions={dynamicOptions}
                                />
                            </KeyboardEventHandler>
                            {
                                buttons ?
                                    <div className="button-wrapper">
                                        <ButtonGroup color="primary" aria-label="primary button group">
                                            {
                                                buttons.map((button, i) => {
                                                    return (
                                                        <Button
                                                            key={`button-${button.label}`}
                                                            variant={button.variant}
                                                            color={button.style}
                                                            type={`${submitable ? 'submit' : 'button'}`}
                                                            className={button.className}
                                                            onClick={button.primary ? handleSubmit : button.onClick}
                                                            disabled={validations && button.primary ? invalid : false}
                                                        >
                                                            {button.label}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </ButtonGroup>
                                    </div> :
                                    <div>
                                        <button type="submit" disabled={pristine || submitting}>
                                            Submit
                                        </button>
                                        <button type="button" disabled={pristine || submitting} onClick={reset}>
                                            Clear Values
                                        </button>
                                    </div>
                            }
                        </form>
                    </Card.Body>
                </Card>
                : <React.Fragment>
                    <div className="form_wrapper">
                        {
                            customHeader ?
                                <div className='form-title'>
                                    <Image className='image-title' src={customHeader}></Image>
                                    <ForumOutlinedIcon className="icon-title" />
                                </div> :
                                <h2 className="form-title">
                                    {formTitle}
                                    <ForumOutlinedIcon className="icon-title" />
                                </h2>
                        }
                        <p className="form-sub-title">
                            {ReactHtmlParser(formSubTitle)}
                        </p>
                        <form tabIndex={0} className={`form_container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={['enter']}
                                onKeyEvent={handleSubmit}>
                                <MaterialUiForm
                                    submitable={submitable}
                                    items={items}
                                    validations={validations}
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit}
                                    buttons={buttons}
                                    dynamicOptions={dynamicOptions}
                                />
                            </KeyboardEventHandler>
                            {
                                buttons ?
                                    <div className="button-wrapper">
                                        <ButtonGroup color="primary" aria-label="primary button group">
                                            {
                                                buttons.map((button, i) => {
                                                    return (
                                                        <Button
                                                            key={`button-${button.label}`}
                                                            variant={button.variant}
                                                            color={button.style}
                                                            type={`${submitable ? 'submit' : 'button'}`}
                                                            className={button.className}
                                                            onClick={button.primary ? handleSubmit : button.onClick}
                                                            disabled={validations && button.primary ? invalid : false}
                                                        >
                                                            {button.label}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </ButtonGroup>
                                    </div> :
                                    <div>
                                        <button type="submit" disabled={pristine || submitting}>
                                            Submit
                                        </button>
                                        <button type="button" disabled={pristine || submitting} onClick={reset}>
                                            Clear Values
                                        </button>
                                    </div>
                            }
                        </form>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: props.formName,
        validate: props.validations,
        // other props...
    }
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        //other redux-form options...
    })
)(CustomReduxForm);