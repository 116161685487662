import React from 'react'

import { Form, InputGroup } from 'react-bootstrap';
import CustomField from './customfield';
import reducer from "./reducer";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reduxForm } from 'redux-form'

const sagaMiddleware = createSagaMiddleware();
const storeFileds = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(watchUsers);

class CustomReduxForm extends React.Component {

  handleButtonClick = () => {
    this.form.reset() // resets "username" field to "admin"
  }

  render() {
  const { items, formName, dataObject, updateAction, className, submitted } = this.props;
  //console.log('dataObject', dataObject);
  return (
        <Form id={`form-${formName}`} className={className} key={`form-${formName}`} name={formName}>
          { dataObject && items && items.map((field, j) => {
              return <InputGroup key={j} className="form-custom-wrapper">
                <Provider store={storeFileds}>
                          <CustomField
                              id={j}
                              label={field.label} 
                              hint={field.hint}
                              type={field.type}
                              typeForm={field.typeForm}
                              value={dataObject[field.name]}
                              options={field.options}
                              disable={field.disable} 
                              placeholder={field.placeholder}
                              updateAction={updateAction}
                              name={field.name}
                              submitted={submitted}
                              className={field.className}
                              stars = {field.stars}
                              cancel = {field.cancel}
                          />
                </Provider>
                      </InputGroup>
          }
        )}
      </Form>
  ) 
}
}

export default reduxForm({
  form: 'syncValidation', // a unique identifier for this form
})(CustomReduxForm)