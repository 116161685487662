import React, { Component } from 'react';
import { connect } from "react-redux";
import { SEND_RESPONSE, START_CONVERSATION } from './constants';
import { Image } from 'react-bootstrap';
/* import 'primereact/resources/primereact.css'; */

/* import { Panel } from 'primereact/panel'; */
/* import { Ripple } from 'primereact/ripple'; */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { getFromSession } from '../../controller/session';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';


class ReplyComponent extends Component {


    renderSimpleReply = (options, title, subtitle, className, actionConv) => {
        return (

            <div className={`reply-wrapper ${className}`}>

                <div className="caption">
                    <p className="caption-title">{title}</p>
                    <span className="caption-subtitle">{subtitle}</span>
                </div>
                <div className="options">  {
                    options.map((optionItem, i) => {
                        const actionResponse = {
                            newMessage: '',
                            conversationData: {
                                agente: {
                                    nombres: 'Chatbot'
                                }
                            },
                            reply: true
                        }
                        /*  console.log('actionResponse', actionResponse); */
                        actionResponse.newMessage = optionItem.value;
                        return <div key={i} onClick={() => actionConv(actionResponse)} className={`option-item ${optionItem.value}`}>{optionItem.label}</div>
                    })
                } </div>
            </div>
        );
    }


    renderListReply = (items, title, subtitle, className, actionConv, image) => {



        return (

            <Accordion className='panelReply'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '& .MuiAccordionSummary-content': {
                            marginTop: "10px",
                            marginBottom: "5px"
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                            marginTop: "10px",
                            marginBottom: "5px"
                        }
                    }}
                >
                    <div >
                        <div className="titleSubtitle">
                            <Grid>
                                <span style={{ "fontWeight": "600" }} className='title'>
                                    {title}
                                </span>

                            </Grid>
                            <Grid>
                                <span className='subtitle'>
                                    {subtitle}
                                </span>
                            </Grid>

                        </div>

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`list-container ${className}`}>
                        <div className="list-items-wrapper">  {
                            items.map((item, i) => {
                                return (
                                    <div key={i} className="options grid">  {
                                        item.options.map((optionItem, j) => {
                                            const actionResponse = {
                                                newMessage: '',
                                                conversationData: {
                                                    agente: {
                                                        nombres: 'Chatbot'
                                                    }
                                                },
                                                reply: true
                                            }
                                            actionResponse.newMessage = optionItem.postbackText;
                                            return (
                                                <div key={j} className="list-item col-6">

                                                    <div className="left-item ">
                                                        <div className="item-title">{optionItem.title}</div>
                                                        <div className="item-subtitle">{optionItem.description}</div>
                                                        <div key={i} onClick={() => actionConv(actionResponse)} className={`option-item ${optionItem.postbackText}`}>
                                                            <div>{optionItem.postbackText}</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        optionItem.imagen && <div className="right-item"><Image className="item-image" src={optionItem.imagen} alt={optionItem.title} /></div>
                                                    }
                                                </div>
                                            );
                                        })
                                    } </div>
                                );
                            })
                        } </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    render() {
        const { options, title, subtitle, className, sendResponse, type, items, image, startConversation } = this.props;
        let rendeReply = false;

        let idConversation = getFromSession('idConversartion');
        let tipoMensajeBienvenida = getFromSession('tipoMensajeBienvenida');

        let actionConv = false

        if (idConversation) {
            actionConv = sendResponse
        } else if (!idConversation && tipoMensajeBienvenida === 'quick_reply') {
            actionConv = startConversation
        }



        if (type === 'quick_reply') {
            rendeReply = this.renderSimpleReply(options, title, subtitle, className, actionConv,);
        } else if (type === 'list') {
            rendeReply = this.renderListReply(items, title, subtitle, className, actionConv, image,);
        }

        return (
            rendeReply

        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispachToProps = dispatch => {
    return {
        sendResponse: (value) => dispatch({ type: SEND_RESPONSE, value }),
        startConversation: (value) => dispatch({ type: START_CONVERSATION, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ReplyComponent);
