import React, { Component } from 'react';
import { Field, FieldArray, FormSection } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Button, ButtonGroup, Switch, Input } from '@material-ui/core';
/* import { FileUpload } from 'primereact/fileupload'; */
import './form.css';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
/* import { Rating } from 'primereact/rating'; */
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
/* import { Captcha } from 'primereact/captcha'; */

export default class MaterialUiForm extends Component {

    renderTextField = ({
        label,
        input,
        placeholder,
        meta: { touched, invalid, error },
        ...custom
        }) => (
        <TextField
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
        />
    )
        
    renderCheckbox = ({ input, label, placeholder }) => (
        <div>
            <FormControlLabel
            control={
                <Checkbox
                checked={input.value ? true : false}
                onChange={input.onChange}
                />
            }
            label={ ReactHtmlParser(placeholder) }
            />
        </div>
    )
        
    radioButton = ({ input, children, ...rest }) => {
        const {options, key} = rest;
        return (
            <FormControl  key={`fcr-${key}`}>
                <RadioGroup key={`rg-${key}`} {...input} {...rest}>
                    {
                        options.map((item, ri) => {
                            return <FormControlLabel key={`ri-${ri}`} value={item.value} control={<Radio />} label={item.label} />
                        })
                    }
                </RadioGroup>
            </FormControl>
    )}
        
    renderFromHelper = ({ touched, error }) => {
        if (!(touched && error)) {
            return
        } else {
            return <FormHelperText>{touched && error}</FormHelperText>
        }
    }

    /* renderStarsButton = ({
        input,
        label,
        meta: { touched, error, invalid },
        children,
        ...custom
    }) => (
        <Rating 
            value={1}
            cancel={false} 
            error={touched && invalid}
            {...input}
        />
        
    ) */

    renderSwitchButton = ({
        input,
        label,
        meta: { touched, error, invalid },
        children,
        ...custom
    }) => (
        <Switch 
            cancel="medium" 
            error={touched && invalid}
            {...input}
        />        
    )

    renderSelectField = ({
        input,
        label,
        meta: { touched, error },
        children,
        ...custom
        }) => (
        <FormControl error={touched && !!error}>
            <InputLabel htmlFor={`${input.name}-native-simple`}>{label}</InputLabel>
            <Select
            native
            {...input}
            {...custom}
            inputProps={{
                name: input.name,
                id: `${input.name}-native-simple`
            }}
            >
            {
                <option key={`option-${input.name}`} value=""></option>
            }
            {
                custom.options.map((item, k) => {
                    return <option 
                            key={`s-option-${k}`} 
                            value={item.value} 
                            >
                                {item.label}
                            </option>
                })
            }
            </Select>
            {this.renderFromHelper({ touched, error })}
        </FormControl>
    )

    renderSpacer = (customKey) => (
        <div 
            className={"custom-input spacer"}
            key={customKey}
        />
    )


    renderChidlren = ({ fields, meta: { touched, error }, ...props  }) => (
        <ul className='form-child'>
            { props.label && 
                <h3>
                    {props.label}
                </h3>
            }
            {fields && fields.length > 0 && fields.map((member, index) =>
            <li key={index}>
                <h4>{props.fieldtitle} #{index + 1}</h4>
                <MaterialUiForm 
                    submitable={false} 
                    items={props.subitems}                           
                    className="field-child"
                    member={member}
                    dynamicOptions={props.dynamicOptions}
                />
                <div className="button-wrapper delete"> 
                    <ButtonGroup color="primary" aria-label="primary button group">
                        <Button 
                            color="secondary"
                            type='button'
                            className='delete'
                            title={props.removeLabel}
                            onClick={() => fields.remove(index)}
                        >
                            {props.removeLabel}
                        </Button>
                    </ButtonGroup>
                </div>
            </li>
            )}
            <li>
                <div className="button-wrapper add"> 
                    <ButtonGroup color="primary" aria-label="primary button group">
                        <Button 
                            color="primary"
                            type='button'
                            className='add'
                            title={props.addLabel}
                            onClick={() => fields.push({})}
                        >
                            {props.addLabel}
                        </Button>
                    </ButtonGroup>
                </div>
                {touched && error && <span>{error}</span>}
            </li>
        </ul>
    )


    render () {
        const { items, member, sectionTitle, changeInputFile, validations, dynamicOptions } = this.props;

        return (
                    <React.Fragment>
                    <div className='field-container'>
                        {
                            sectionTitle && 
                            <h3>
                                {sectionTitle}
                            </h3>
                        }

                    {
                        items.map((field, j) => {
                            let renderComponent = {};

                            field.options = dynamicOptions && dynamicOptions[field.name] ? dynamicOptions[field.name] : field.options;

                            const specialName = member ? `${member}.${field.name}` : field.name;

                            switch (field.typeForm) {
                                case 'text':
                                    renderComponent = this.renderTextField;
                                    break;
                                case 'radio':
                                    renderComponent = this.radioButton;
                                    break;
                                case 'checkbox':
                                    renderComponent = this.renderCheckbox;
                                    break;
                                case 'select':
                                    renderComponent = this.renderSelectField;
                                    break;
                                case 'switch':
                                        renderComponent = this.renderSwitchButton;
                                        break;    
                                case 'spacer':
                                        renderComponent = this.renderSpacer;
                                        break;
                                case 'array':
                                        renderComponent = this.renderChidlren;
                                        break;
                                case 'stars': 
                                        renderComponent = this.renderStarsButton;
                                        break;
                                default:
                                    renderComponent = this.renderTextField;
                                    break;
                            }

                            return (
                                <div className={`material-field ${field.hidden ? 'no-visible' :  ''} ${field.className}`} key={j}>
                                    {
 
                                        field.typeForm === 'array' ? 
                                            <FieldArray 
                                                addLabel={field.addLabel} 
                                                removeLabel={field.removeLabel} 
                                                subitems={field.fields} 
                                                name={field.name} 
                                                label={field.label} 
                                                fieldtitle={field.fieldtitle} 
                                                component={renderComponent}
                                                dynamicOptions={dynamicOptions}
                                                />
                                            :  field.typeForm === 'section' ?
                                                <div className="section-container">
                                                    <FormSection name={field.name} >
                                                        <MaterialUiForm 
                                                            sectionTitle={field.label}
                                                            submitable={false} 
                                                            items={field.fields}                           
                                                            className="field-child"
                                                            member={member}
                                                            dynamicOptions={dynamicOptions}
                                                            validations={validations} 
                                                        />
                                                    </FormSection>
                                                </div>
                                          /*   : field.typeForm === 'captcha' ? 
                                                <Captcha siteKey="APP_REACT_CHAT_KEY" onResponse={field.showResponse}></Captcha> */
                                            /* : field.typeForm === 'file' ? 
                                            <React.Fragment>
                                                {
                                                    field.tooltip && 
                                                    <Tooltip className="form-tooltip" title={field.tooltip} aria-label="add">
                                                            <HelpIcon />
                                                    </Tooltip>
                                                }
                                                {field.label &&
                                                <label className="label-name">
                                                    <span className="content-name">{ReactHtmlParser(field.label)}</span>
                                                </label>
                                                }
                                                <FormControl >
                                                    <FileUpload 
                                                        maxFileSize={1000000}
                                                        customUpload 
                                                        uploadHandler={changeInputFile} 
                                                        chooseLabel={field.placeholder}
                                                        mode="basic" />
                                                </FormControl>
                                            </React.Fragment> */
                                            :
                                                <React.Fragment>
                                                    {
                                                        field.tooltip && 
                                                        <Tooltip className="form-tooltip" title={field.tooltip} aria-label="add">
                                                                <HelpIcon />
                                                        </Tooltip>
                                                    }
                                                    {field.label &&
                                                    <label className="label-name">
                                                        <span className="content-name">{ReactHtmlParser(field.label)}</span>
                                                    </label>
                                                    }
                                                    <Field
                                                        name={specialName}
                                                        component={renderComponent}
                                                        label={field.type === "date" ? '' : field.label}
                                                        placeholder={field.placeholder}
                                                        options={field.options}
                                                        multiline={field.multiline}
                                                        rows={field.rows}
                                                        key={`field-${j}`}
                                                        subitems = {field.fields}
                                                        type={field.type}
                                                        disabled={field.disabled}
                                                        className={field.type === "date" ? 'datepicker' : ''}
                                                    >
                                                        {
                                                            field.typeForm === 'select' && <option key={`option-${j}`} value=""></option>
                                                        }
                                                        {
                                                            field.options && field.options.map((item, k) => {
                                                                if (field.typeForm === 'radio') {
                                                                    return <Radio key={`radio-${k}`} value={item.value} label={item.label} >{item.label}</Radio>
                                                                } else if (field.typeForm === 'select') {
                                                                    return <option key={`s-option-${k}`} value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </Field>
                                                </React.Fragment>
                                    }
                                </div>
                            )
                        })
                    }
                    </div>
                    </React.Fragment>
        );
    }
}