import React, { Component } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';


export default class CustomButton extends Component {
    render() {
        const { items } = this.props;

        return (
            <div className="button-wrapper whiteBackground"> 
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    {
                        items && items.map((button, k) => {
                            return <Button
                                        key={`button-${button.label}-${k}`} 
                                        variant={button.variant}
                                        color={button.style}
                                        type="submit"
                                        className={button.className}
                                        onClick={button.onClick}
                                    >
                                        {button.label}
                                    </Button>
                        })
                    }  
                </ButtonGroup>
            </div>
        );
    }
}
