import React, { Component } from "react";
import "./style.css";
import { isMobile } from 'react-device-detect';
import { connect } from "react-redux";
import { call } from "redux-saga/effects";
import CustomReduxForm from "../../components/materialform/customform";
import beep from '../../static/sounds/beep1sec.mp3';
import 'react-h5-audio-player/lib/styles.css';
/* import { FileUpload } from 'primereact/fileupload'; */
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-h5-audio-player/lib/styles.css';
import $ from 'jquery';
import { Beforeunload } from 'react-beforeunload';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import AddIcon from '@mui/icons-material/Add';
import {
    getResponseMessage, getIdConversation, getSelectedCompanyForm, getConversationData, getNewChat,
    getCompany, getCompanyId, getHorario, getReduxCompanyForm, getPlaySound, getInfoEncuesta,
    getInfoFormClient, enableFormClient, getUploading, esperandoConv, getStartWidget,
    creandoConversacion, isEnabledCaptchaValidation, sendingFlow, iniciaChatAbierto, chatOpened, whatsAppMobileWeb, numWhatsApp
} from "./selectors";



import {
    LISTENER_RESPONSE,
    TEXT_RESPONSE,
    SEND_RESPONSE,
    START_CONVERSATION,
    LOAD_CONVERSATION,
    getLoginButton,
    UPDATE_FIELD_ATTRIBUTE,
    getEncuestaButton,
    FORM_ENCUESTA,
    START_WIDGET,
    ENCUESTA_CONF,
    SHOW_WIDGET,
    HANDLER_FILE_MESSAGE,
    SVG_ICONO_EQUIS,
    CLIENTE_ALCALDIA,
    ICONO_SEND_OSCURO,
    INICIAR_CHAT_ABIERTO,
    CAMBIAR_ESTADO_CHAT_ABIERTO,
    ICONO_SEND_RED
} from './constants';

import { Widget, dropMessages } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { storeInSession, getFromSession, removeItems } from "../../controller/session";
import { ReactMic } from 'react-mic';
import CustomForm from "../../components/customform/customform";
/* import { Message } from 'primereact/message'; */
import { IconButton } from "@material-ui/core";
import { Mic, SendRounded } from "@material-ui/icons";
import { CustomCaptcha, CustomSpinner, RichText } from "./components";
import InputFiles from 'react-input-files';
import { renderCustomComponent } from 'react-chat-widget';



class ComponentAuth extends Component {
    constructor(props) {
        super(props);

        const { startWidget, containerData } = props;

        startWidget(containerData);

        this.state = {
            record: false,
            valueCaptcha: '',
            captchaValidado: false,
            errorCaptchaInvalido: false,
            whatsAppMobile: false,
           modeMobile:false,


        }
        this.onStop = this.onStop.bind(this);
        this.doSubmit = this.doSubmit.bind(this);
    }
    startRecording = () => {
        this.setState({ record: true });
        const soundWave = document.querySelector('.sound-wave')
        soundWave.style.display = 'block'
        const rwcSender = document.querySelector('.rcw-send')
        const sendAudio = document.querySelector('.attach-widget-send-audio')
        if (rwcSender) {
            rwcSender.style.display = 'none'
        }
        if (sendAudio) {
            sendAudio.style.display = 'block'
        }

        soundWave.setAttribute('title', "...Grabando Audio")
    }

    stopRecording = () => {
        this.setState({ record: false });
    }

    onData(recordedBlob) {
        //console.log('chunk of real-time data is: ', recordedBlob);
    }

    lauchChatWidget = () => {
        const { cambiarEstadoChatAbiertoTrue } = this.props

        setTimeout(() => {

            let button = $(".chat-closed .rcw-launcher");
            let buttonChat = $(".rcw-widget-container .rcw-launcher");
            let body = $('body');

            if (buttonChat) {
                //console.log('single buttonChat');
                buttonChat.click();
                body.addClass('chat-opened');
            } else if (button) {
                //console.log('single button');
                button.click();
            }

            cambiarEstadoChatAbiertoTrue()
        }, 2500);

    }

    onStop(recordedBlob) {
        //console.log('***chunk of onStop: ***', recordedBlob)
        const sessionObject = getFromSession('idConversartion');
        const { idConversartion, startConversation } = sessionObject ? sessionObject : {};
        const { handlerFileMessage } = this.props;
        const soundWave = document.querySelector('.sound-wave').style.display = 'none';
        const rwcSender = document.querySelector('.rcw-send');
        rwcSender.style.display = 'block'
        const sendAudio = document.querySelector('.attach-widget-send-audio')
        if (sendAudio) {
            sendAudio.style.display = 'none'
        }
        if (!idConversartion) {
            startConversation(recordedBlob)
        } else {
            handlerFileMessage({ files: [recordedBlob], isVoiceRecord: true })
        }

    }



    validations = (values) => {
        const errors = {}

        const requiredFields = [
            'mail',
            'terminos',
            'nombre',
            'subject',
            'related',
        ]

        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Este campo es obligatorio'
            }
        })
        if (
            values.mail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)
        ) {
            errors.mail = 'El correo ingresado no es inválido'
        }

        return errors
    }

    onUnload = e => {
        e.preventDefault();
        const clienteBody = {
            company: '1020',
            nombre: 'cerrada',
            mail: 'cerrada',
            motivo: 'cerrada'
        }
        const urlConv = 'https://us-central1-chat-asesores-3.cloudfunctions.net/nuevaConversacionWeb';
        const response = call(fetch, urlConv, {
            method: 'POST',
            headers: {
                accept: 'application/json, text/plain, */*',
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
                'cache-control': 'no-cache',
            },
            body: JSON.stringify(clienteBody)
        });

        //alert('adasdf')
    };


    cerrar() {
        let company = getFromSession('company_id')
        let idConv = getFromSession('idConversartion')
        var url = process.env.REACT_APP_ABANDONO;
        var url = url + '?idConv=' + idConv.idConversartion + '&idCompany=' + company;
        navigator.sendBeacon(url, '');
    }


    componentDidMount() {

        const { listenerResponse, containerData, company, chatOpened } = this.props;
        const sessionObject = getFromSession('idConversartion');
        const { idConversartion } = sessionObject ? sessionObject : {};
        const chatLauncher = document.querySelector('.rcw-launcher').style.display = 'none';



        let chatClosedLauncher = $('.rcw-close-widget-container .rcw-launcher');

        //cambio pjf para que el iconContainer este dentro del widget:
        $('.rcw-widget-container').append($('.iconsContainer'));

        if (chatClosedLauncher) {

            if (containerData.company === CLIENTE_ALCALDIA) {

                var buttonImage = document.querySelector('.rcw-launcher img');
                buttonImage.setAttribute('src', "https://firebasestorage.googleapis.com/v0/b/chat-asesores-3.appspot.com/o/alcaldiaBogota%2FbotonChatico.png?alt=media&token=db90b93a-c780-4ade-a611-20d5b193e97c");
            }

            chatClosedLauncher.title = 'Hola ¿En qué puedo ayudarte?';
        }
        const $this = this;


        $(document).on('click', '.fake-button.close', () => {
            $this.hideConversation();
        });

        if (idConversartion) {

            storeInSession('idConversartion', JSON.stringify({ idConversartion }));
            listenerResponse(idConversartion);

        } else {
            const chatContainer = document.querySelector('.rcw-widget-container');
            chatContainer.classList.add('chat-closed');

            $(document).on('click', '.chat-closed .rcw-launcher', () => {
                $this.showConversation();

            });

        }


        $(document).on('click', '.rcw-close-button', (e) => {
            $('body').removeClass('chat-opened');
            let rcwLauncher = document.querySelector('.rcw-launcher');
            rcwLauncher.style.display = 'initial';
                    
            document.querySelector('.main-wrapper').setAttribute('style', 'z-index: 99!important');
            document.querySelector('.rcw-widget-container').setAttribute('style', 'width: 0%!important');
            const soundWave = document.querySelector('.sound-wave')
            if (soundWave) {
                soundWave.style.display = 'none';

            }
            const sendAudio = document.querySelector('.attach-widget-send-audio')

            if (sendAudio) {
                sendAudio.style.display = 'none';
            }

            if (containerData.company === CLIENTE_ALCALDIA) {

                var containerMessages = document.querySelector('.chat-opened');
                var button = document.querySelector('.rcw-launcher');
                var buttonImage = document.querySelector('.rcw-launcher img');
                if (containerMessages == null || !containerMessages) {

                    if (buttonImage) {
                        button.style.padding = "0px 0px 0px 0px";
                        buttonImage.style.width = "62px"
                        buttonImage.setAttribute('src', "https://firebasestorage.googleapis.com/v0/b/chat-asesores-3.appspot.com/o/alcaldiaBogota%2FbotonChatico.png?alt=media&token=db90b93a-c780-4ade-a611-20d5b193e97c");
                    }
                } else {
                    buttonImage.removeAttribute("width")
                    buttonImage.style.width = "20px"
                    button.style.width = "48px"
                    button.style.height = "48px"

                    buttonImage.setAttribute('src', SVG_ICONO_EQUIS)
                }
            }
        });

        $(document).on('click', '.rcw-launcher', (e) => {

            const $this = $(e.currentTarget);
            let rcwLauncher = document.querySelector('.rcw-launcher');
            let container = document.querySelector('.rcw-conversation-container')
            if ($this.hasClass('rcw-hide-sm')) {
              
                if (this.state.whatsAppMobile) {
                    rcwLauncher.style.display = 'initial';
                    container.style.display = 'none'
                }else if(!this.state.modeMobile){
                    rcwLauncher.style.display = 'initial';
                }else{
                    rcwLauncher.style.display = 'none';
                }

                $('body').addClass('chat-opened');
                if (this.state.record) {
                    const soundWave = document.querySelector('.sound-wave')
                    soundWave.style.display = 'block';
                    const rwcSender = document.querySelector('.rcw-send');
                    rwcSender.style.display = 'none'
                    const sendAudio = document.querySelector('.attach-widget-send-audio')
                    if (sendAudio) {
                        sendAudio.style.display = 'block';
                    }
                }
                document.querySelector('.main-wrapper').setAttribute('style', 'z-index: 111!important');
                document.querySelector('.rcw-widget-container').setAttribute('style', '');
            } else {


                $('body').removeClass('chat-opened');
                const soundWave = document.querySelector('.sound-wave')
                const sendAudio = document.querySelector('.attach-widget-send-audio')
                if (soundWave) {
                    soundWave.style.display = 'none';
                }
                if (sendAudio) {
                    sendAudio.style.display = 'none';
                }

            }

        })

        if (chatOpened) {
            this.lauchChatWidget();
        }
    }




    doSubmit = (value) => {
        const { startConversation } = this.props
        if (validateCaptcha(value) === true) {
            //alert('Captcha Matched');
            loadCaptchaEnginge(6);
            this.setState({
                valueCaptcha: "",
                captchaValidado: true,
            })
            startConversation({ subject: "hola", captcha: true })
        }
        else {
            //alert('Captcha Does Not Match');
            this.setState({
                valueCaptcha: "",
                captchaValidado: false,
                errorCaptchaInvalido: true
            })
        }
    };

    getCustomLauncher = (handleToggle) =>
        <button onClick={handleToggle}>CHAT!</button>

    addCustomButtonLogo(imagePath) {
        var button = document.querySelector('.rcw-launcher img');


        if (button) {

            button.setAttribute('src', imagePath);
        }

    }


    addCustomHeader(imageSource) {
        setTimeout(() => {
            let chatHeader = document.querySelector('.rcw-header');
            let currentImage = document.querySelector('.chat-image-header');
            let imagePath = imageSource ? imageSource : '';


            var image = document.createElement("IMG");
            image.setAttribute('class', 'chat-image-header');
            if (imagePath) {
                image.setAttribute("src", imagePath);
            }

            if (chatHeader) {

                if (currentImage !== undefined) {
                    $('.chat-image-header').remove();
                }
                chatHeader.appendChild(image);
            }
        }, 10);
    }

    componentDidUpdate(prevProps) {

        const { playSound, enableFormClient, containerData, startWidget, startWidgetFlag, flagNewChat,
            conversationData, enviarEncuesta, showCaptcha, iniciaChatAbierto, cambiarEstadoChatAbiertoFalse, company, whatsAppMobileWeb, numWhatsApp } = this.props;
        const sessionObject = getFromSession('idConversartion');
        const { idConversartion } = sessionObject ? sessionObject : {};



        var containerMessages = document.querySelector('.chat-opened');
        if (document.querySelector('.rcw-launcher')) {
            document.querySelector('.rcw-launcher').title = company.botonTitle ? company.botonTitle : 'Hola ¿En qué puedo ayudarte?';
        }

        if (iniciaChatAbierto || containerMessages) {

            var iconSend = document.querySelector('.rcw-send img')
            var button = document.querySelector('.rcw-launcher');
            if (iconSend) {

                if (containerData.company === CLIENTE_ALCALDIA) {

                    button.style.width = "48px"
                    button.style.height = "48px"
                    button.style.backgroundColor = "#d82200"
                    iconSend.setAttribute('src', ICONO_SEND_RED);
                } else {
                    iconSend.setAttribute('src', ICONO_SEND_OSCURO);
                }

                iconSend.setAttribute('title', "Enviar mensaje")
            }
            cambiarEstadoChatAbiertoFalse()

        }

        let imageSource = false;
        let canvasCaptcha = document.querySelector('#canv');
        let inputMessage = document.querySelector(".rcw-new-message");
        let textInput = document.querySelector('.chat-widget-panel');

        if (this.props.startWidgetFlag && prevProps.startWidgetFlag !== this.props.startWidgetFlag
        ) {

            let rcwLauncher = document.querySelector('.rcw-launcher');
            let rcwHide = document.querySelector('.rcw-hide-sm');
            if (rcwLauncher) {
                rcwLauncher.style.display = 'initial';
                if (whatsAppMobileWeb && isMobile) {
                    this.setState({
                        whatsAppMobile: true
                    })
                }else if(isMobile){
                    this.setState({
                        modeMobile: true
                    })
                }
            }




            $(document).on('click', '.rcw-launcher', (e) => {
                const buttonImage = document.querySelector('.rcw-launcher img');
                const button = document.querySelector('.rcw-launcher');
                if (whatsAppMobileWeb && isMobile) {
                    this.setState({
                        whatsAppMobile: true
                    })

                    if (buttonImage) {
                        button.style.padding = "0px 0px 0px 0px";
                        buttonImage.style.width = "73px";
                        button.style.width = "70px"
                        button.style.height = "70px"
                        buttonImage.setAttribute('src', "https://firebasestorage.googleapis.com/v0/b/chat-asesores-3.appspot.com/o/alcaldiaBogota%2FbotonChatico.png?alt=media&token=db90b93a-c780-4ade-a611-20d5b193e97c");
                        button.style.backgroundColor = "rgba(0, 0, 0, 0)"
                    }
                  window.open(`https://wa.me/+${numWhatsApp}?text=Hola`, '_blank')

                } else {
                    imageSource = this.props.company ? this.props.company.logoNombre : false;
                    this.addCustomHeader(imageSource);
                    let iconSend = document.querySelector('.rcw-send-icon')
                    if (iconSend) {
                        if (containerData.company === CLIENTE_ALCALDIA) {

                            iconSend.setAttribute('src', ICONO_SEND_RED);
                        } else {
                            iconSend.setAttribute('src', ICONO_SEND_OSCURO);
                        }
                        iconSend.setAttribute('title', "Enviar mensaje")
                    }

                    if (containerData.company === CLIENTE_ALCALDIA) {
                        var containerMessages = document.querySelector('.chat-opened');

                        if (containerMessages == null && !iniciaChatAbierto) {

                            if (buttonImage) {
                                button.style.padding = "0px 0px 0px 0px";
                                buttonImage.style.width = "73px";
                                button.style.width = "70px"
                                button.style.height = "70px"
                                buttonImage.setAttribute('src', "https://firebasestorage.googleapis.com/v0/b/chat-asesores-3.appspot.com/o/alcaldiaBogota%2FbotonChatico.png?alt=media&token=db90b93a-c780-4ade-a611-20d5b193e97c");
                                button.style.backgroundColor = "rgba(0, 0, 0, 0)"
                            }
                        } else {
                            button.style.backgroundColor = "#d82200"
                            buttonImage.removeAttribute("width")
                            buttonImage.style.width = "20px"
                            button.style.width = "48px"
                            button.style.height = "48px"
                            buttonImage.setAttribute('src', SVG_ICONO_EQUIS)
                        }
                    }
                }

            });
        }

        if (showCaptcha && prevProps.showCaptcha !== showCaptcha && conversationData === false) {
            if (canvasCaptcha) {
                loadCaptchaEnginge(6, 'red', 'white');
            }
        }

        if (conversationData) {
            if (inputMessage) {
                inputMessage.removeAttribute('disabled');
            }
        }

        if (prevProps.conversationData?.estado === 10 && !conversationData) {
            if (canvasCaptcha) {
                loadCaptchaEnginge(6)
            }
        }
        if (this.props.creandoConversacion && prevProps.creandoConversacion !== this.props.creandoConversacion) {
            this.setState({
                captchaValidado: false
            })
        }
        if (enableFormClient && idConversartion) {
            if (textInput) {
                document.querySelector('.chat-widget-panel').classList.remove('container-disabled');
            }
        } else if (!enableFormClient && !idConversartion || !enableFormClient && idConversartion) {
            if (textInput) {
                document.querySelector('.chat-widget-panel').classList.remove('container-disabled');

            }

        }
        if (flagNewChat) {
            let container = document.querySelector('.rcw-widget-container')
            if (container) {
                container.setAttribute('style', 'width: 100%!important');
            }
        }
        if (conversationData && conversationData.agente && conversationData.agente.nombres === "Chatbot" && conversationData.estado === 10) {
            enviarEncuesta(false)
        }
        if (!startWidgetFlag) {
            this.openNewChat();
            startWidget(containerData);
            imageSource = this.props.company ? this.props.company.logoNombre : false;
            this.addCustomHeader(imageSource);
        }
        // if (playSound) {
        //     const audioEl = document.getElementById("audio-element");
        //     audioEl.play();
        // }



        // this.addCustomButtonLogo(this.props.company.avatar);
    }

    showConversation() {

        const { showCaptcha, conversationData, enableFormClient, clientFormConf } = this.props
        let inputMessage = document.querySelector(".rcw-new-message");
        if (document.querySelector('.form-container')) {
            document.querySelector('.form-container').style.display = 'flex';
        }

        if (!conversationData && (enableFormClient || showCaptcha || clientFormConf)) {
            if (inputMessage) {
                //console.log('show conversation, inactivate input messages');
                inputMessage.setAttribute('disabled', true)
            }
        }

        const chatContainer = document.querySelector('.rcw-widget-container');
        chatContainer.classList.remove('chat-closed');
    }

    hideConversation() {

        let rcwHideButton = document.querySelector('.rcw-launcher');
        let rcwContainer = document.querySelector('.form-container');

        if (rcwHideButton) {
            rcwHideButton.click();
        }

        if (rcwContainer) {
            rcwContainer.style.display = 'none';
        }
        const chatContainer = document.querySelector('.rcw-widget-container');
        chatContainer.classList.add('chat-closed');
    }

    openNewChat() {
        if (document.querySelector('.form-container')) {
            document.querySelector('.form-container').style.display = 'flex';
        }

    }

    onFileUpload = (e) => {
        const { handlerFileMessage } = this.props
       
        handlerFileMessage(e)


    }



    render() {
        const {
            sendResponse,
            updateAttributes,
            message,
            selectedCompanyForm, startConversation,
            conversationData,
            enviarEncuesta,
            company, horario,
            reduxCompanyForm,
            encuestaConf,
            clientFormConf,
            enableFormClient,
            startWidgetFlag,
            handlerFileMessage,
            uploading,
            creandoConversacion,
            showCaptcha,
            sendingFlow,
            startWidget,
            containerData
        } = this.props;



        const mostrarChat = (startWidgetFlag && !enableFormClient);
        const handleNewUserMessage = (newMessage) => {

            sendResponse({ newMessage, conversationData });
        };

        function sortObjectByKeys(o) {
            return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
        }

        let parametrosEncuesta = (encuestaConf) => {
            let form = [];
            if (encuestaConf) {
                let ordenado = sortObjectByKeys(encuestaConf)
                for (let pregunta in ordenado) {
                    let jsonBase = {
                        label: ordenado[pregunta].nombre,
                        name: ordenado[pregunta].nombreCorto,
                        placeholder: "",
                        hint: "",
                        type: ordenado[pregunta].type,
                        typeForm: ordenado[pregunta].type,
                        value: false,
                        options: [],
                        disable: false,
                    }

                    if (ordenado[pregunta].type === 'ratingStars') {
                        jsonBase['cancel'] = false
                        jsonBase['className'] = "rs-100 fixed-margin"
                        jsonBase['stars'] = 5

                        form.push(jsonBase)
                    } else {
                        jsonBase['options'] = ordenado[pregunta].opcionesRespuesta;
                        jsonBase['className'] = "rs-100"
                        form.push(jsonBase)
                    }
                }
            }
            return form;
        }

        let parametrosFormCliente = (formClienteConf) => {
            let form = [];
            if (clientFormConf) {
                let ordenado = sortObjectByKeys(formClienteConf);
                for (let pregunta in ordenado) {
                    let jsonBase = {
                        label: ordenado[pregunta].nombre,
                        name: ordenado[pregunta].nombreCorto,
                        placeholder: "",
                        hint: "",
                        type: ordenado[pregunta].type,
                        typeForm: ordenado[pregunta].type,
                        value: false,
                        options: [],
                        disable: false,
                    }

                    if (ordenado[pregunta].type === 'select') {
                        jsonBase['options'] = ordenado[pregunta].opcionesRespuesta;
                        jsonBase['className'] = "rs-100"
                        form.push(jsonBase);

                    } else if (ordenado[pregunta].type === 'radio') {
                        jsonBase['options'] = ordenado[pregunta].opcionesRespuesta;
                        jsonBase['className'] = "rs-100 radio radioTerms"
                        form.push(jsonBase);
                    } else {
                        jsonBase['className'] = "rs-100"
                        form.push(jsonBase);
                    }
                }
            } else {
                form = false;
            }
            return form;

        }

        const sessionObject = getFromSession('idConversartion');
        const { idConversartion } = sessionObject ? sessionObject : {};
        let subtitle = `${horario ? horario.textoHorario : ''}`;
        let estadoConv = conversationData && conversationData.estado ? conversationData.estado : null;

        if (idConversartion === undefined) {
            estadoConv = false;
        }


        if (estadoConv === 11) {
            //dropMessages();
            removeItems('clienteData');
            removeItems('idConversartion');
            startWidget(containerData);
        }




        let spinner = <div></div>

        this.addCustomHeader(company.logoNombre);

        return (
            <div className={`chat-demo-wrapper chatico-${company.cuenta}`}>
                <Beforeunload onBeforeunload={(event) => { this.cerrar(); }} />
                {!idConversartion ?
                    <div>
                        {enableFormClient && clientFormConf ?
                            <div className="form-container formInicio" id="formInicio" >
                                {creandoConversacion ? (spinner) :
                                    <React.Fragment>
                                        <CustomReduxForm
                                            formName="chatReduxForm"
                                            customHeader={company.logo}
                                            formTitle={company.nombre}
                                            formSubTitle={subtitle}
                                            items={parametrosFormCliente(clientFormConf)}
                                            className="conversation"
                                            card={false}
                                            handleSubmit={() => startConversation(reduxCompanyForm)}
                                            validations={this.validations}
                                            buttons={horario && horario.valido ? getLoginButton(() => startConversation(reduxCompanyForm)) : []}
                                        />
                                        {horario && !horario.valido ?
                                            <FormControlLabel value="worst" label="Estimado usuario, se encuentra fuera de horario para ser atendido." /> : ''}

                                    </React.Fragment>

                                }

                                {!creandoConversacion &&
                                    <React.Fragment>
                                        <div className="fake-field"></div>
                                        <div className="fake-button close">
                                            <button className="rcw-close-button rcw-fake-close-button">
                                                <img src={SVG_ICONO_EQUIS} className="rcw-close" alt="close" />
                                            </button>
                                        </div>
                                    </React.Fragment>}
                            </div>
                            : (showCaptcha &&
                                <div className="form-container formInicio formCaptcha" id="formInicio" >
                                    {creandoConversacion ? (spinner) :
                                        <CustomCaptcha
                                            state={this.state}
                                            subtitle='Para comprobar que es un humano escriba los caracteres que ve en el siguiente recuadro para poder
                                            continuar al chat.'
                                            logo={company.logo}
                                            reloadText="Cambiar caracteres"
                                            doSubmit={this.doSubmit}
                                            setState={this.setState}
                                            renderThis={this}
                                            svgIconX={SVG_ICONO_EQUIS}
                                            placeholder='Escriba el valor del captcha'
                                        />
                                    }
                                </div>
                            )
                        }
                    </div>
                    : false
                }


                {
                    conversationData && conversationData.agente && conversationData.agente.nombres !== "Chatbot" && estadoConv && estadoConv === 10 && encuestaConf ? (
                        <div className="form-container zindex2000 encuestaStyles">
                            <CustomForm
                                formName="conversationForm"
                                items={parametrosEncuesta(encuestaConf)}
                                formTitle="Encuesta de Satisfacción"
                                customHeader={company.logo}
                                formSubtitle="La conversación ha finalizado, ayúdanos a mejorar calificando el servicio prestado."
                                values={selectedCompanyForm}
                                submitted={message}
                                updateAction={updateAttributes}
                                buttons={getEncuestaButton(() => enviarEncuesta(selectedCompanyForm))}
                                className="conversation scroll"
                            >
                            </CustomForm>
                        </div>
                    ) : null
                }

                {
                    (mostrarChat && estadoConv === undefined || !estadoConv || estadoConv !== 10) &&
                    <div className={`chat-widget-panel container-disabled ${sendingFlow ? 'df-sending-msg' : ''}`}>
                        {sendingFlow && <CustomSpinner
                            text={false}
                            className='dfSpinnerContainer'
                            spinnerClassName='dialogFlowSpinner'
                            color='secondary'
                            textClassName='p-text-normal df-cargando'
                            id='dialogFlowSpinner'
                            type='linear'
                        />
                        }
                        <img alt="company_logo" src={company.logo} className='logo-value hidden'></img>
                        <Widget
                            title={company.nombre}
                            subtitle=''
                            className={`chat-widget-container`}
                            senderPlaceHolder='¿En qué te puedo ayudar?'
                            handleNewUserMessage={!idConversartion ? startConversation : handleNewUserMessage}
                            profileAvatar={company.avatar}
                            toggleInputDisabled={false}
                            titleAvatar={company.logoNombre}
                            toggleWidget={true}


                        /* launcherCloseImg={"https://firebasestorage.googleapis.com/v0/b/chat-asesores-prueba.appspot.com/o/alcaldiaBogota%2Flogo_chatico%20(2).png?alt=media&token=d3e50bea-2ab0-4ea1-a253-c4ad880169eb"}
                        launcherOpenImg = {"https://firebasestorage.googleapis.com/v0/b/chat-asesores-prueba.appspot.com/o/alcaldiaBogota%2Flogo_chatico%20(2).png?alt=media&token=d3e50bea-2ab0-4ea1-a253-c4ad880169eb"} */
                        >
                        </Widget>


                        {uploading ?

                            <div >

                            </div>

                            :
                            <div className="iconsContainer">
                                {idConversartion ? <div className="iconoMicrofono">
                                    <ReactMic
                                        record={this.state.record}
                                        className="sound-wave "
                                        onStop={this.onStop}
                                        onData={this.onData}
                                        strokeColor="#FFFFFF"
                                        backgroundColor="#DC143C"
                                        channelCount={1}
                                        mimeType="audio/wav; codecs=MS_PCM" />
                                    <IconButton
                                        className="attach-widget-microphone"
                                        onClick={this.startRecording}
                                        title="Grabar voz"
                                    >
                                        <Mic style={{ height: '1.3em', width: '2em' }} />
                                    </IconButton>
                                    <IconButton
                                        className="attach-widget-send-audio"
                                        onClick={this.stopRecording}
                                        title="Parar y enviar audio"
                                    >
                                        <SendRounded />
                                    </IconButton>

                                </div> : false
                                }

                                {idConversartion ? <div className="iconoAdjuntar">
                                    <InputFiles accept="*" onChange={this.onFileUpload}>
                                        <IconButton className='attach-widget-file' title="Adjuntar archivo" aria-label="adjuntar" >

                                            <AddIcon />
                                        </IconButton>
                                    </InputFiles>

                                </div> : false}
                            </div>
                        }
                    </div>
                }
                {/* <audio id="audio-element" >
                    <source src={beep}></source>
                </audio> */}
            </div >



        );
    }
}

const mapStateToProps = state => {
    return {
        responseMessage: getResponseMessage(state),
        idConversartionState: getIdConversation(state),
        reduxCompanyForm: getReduxCompanyForm(state),
        selectedCompanyForm: getSelectedCompanyForm(state),
        esperandoConv: esperandoConv(state),
        conversationData: getConversationData(state),
        flagNewChat: getNewChat(state),
        creandoConversacion: creandoConversacion(state),
        company: getCompany(state), companyId: getCompanyId(state),
        horario: getHorario(state),
        playSound: getPlaySound(state),
        encuestaConf: getInfoEncuesta(state),
        clientFormConf: getInfoFormClient(state),
        enableFormClient: enableFormClient(state),
        uploading: getUploading(state),
        startWidgetFlag: getStartWidget(state),
        showCaptcha: isEnabledCaptchaValidation(state),
        sendingFlow: sendingFlow(state),
        iniciaChatAbierto: iniciaChatAbierto(state),
        chatOpened: chatOpened(state),
        whatsAppMobileWeb: whatsAppMobileWeb(state),
        numWhatsApp: numWhatsApp(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        sendResponse: (value) => dispatch({ type: SEND_RESPONSE, value }),
        listenerResponse: (value) => dispatch({ type: LISTENER_RESPONSE, value }),
        textResponse: (value) => dispatch({ type: TEXT_RESPONSE, value }),
        startConversation: (value) => dispatch({ type: START_CONVERSATION, value }),
        loadConversation: (value) => dispatch({ type: LOAD_CONVERSATION, value }),
        listenerConversationInfo: (value) => dispatch({ type: LISTENER_RESPONSE, value }),
        enviarEncuesta: (value) => dispatch({ type: FORM_ENCUESTA, value }),
        startWidget: (value) => dispatch({ type: START_WIDGET, value }),
        traerPreguntasEncuesta: (value) => dispatch({ type: ENCUESTA_CONF, value }),
        mostrarWidget: (value) => dispatch({ type: SHOW_WIDGET, value }),
        handlerFileMessage: (value) => dispatch({ type: HANDLER_FILE_MESSAGE, value }),
        cambiarEstadoChatAbiertoTrue: (value) => dispatch({ type: INICIAR_CHAT_ABIERTO, value }),
        cambiarEstadoChatAbiertoFalse: (value) => dispatch({ type: CAMBIAR_ESTADO_CHAT_ABIERTO, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentAuth);