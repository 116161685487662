import {
  SEND_RESPONSE,
  START_CONVERSATION_SUCCESS,
  UPDATE_FIELD_ATTRIBUTE,
  LISTENER_RESPONSE_SUCCESS,
  FORM_ENCUESTA_SUCCESS,
  START_CONVERSATION,
  FORM_ENCUESTA,
  START_WIDGET_SUCCES,
  PLAY_SOUND,
  ENCUESTA_CONF_SUCCESS,
  CHANGE_FLAG_WIDGET,
  HANDLER_FILE_MESSAGE,
  HANDLER_FILE_MESSAGE_SUCCESS,
  SHOW_WIDGET,
  SENDING_MSG_DF,
  INICIAR_CHAT_ABIERTO,
  CAMBIAR_ESTADO_CHAT_ABIERTO,

} from './constants';

export const initialState = {
  responseMessage: false,
  idConversation: false,
  selectedCompanyForm: {},
  esperandoConv: false,
  dataConversation: false,
  flagNewChat: false,
  startWidget: false,
  company: false,
  company_id: false,
  horario: false,
  playSound: false,
  infoEncuesta: false,
  infoFormClient: false,
  enableFormClient: false,
  showWidget: false,
  uploading: false,
  botEnabled: false,
  creandoConversacion: false,
  isEnabledCaptchaValidation: false,
  sendingFlow: false,
  iniciaChatAbierto:false,
  whatsAppMobileWeb:false,
  numWhatsApp:false
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;

  newState.esperandoConv = true;
  newState.playSound = false;
  let newFormCompanyObject = { ...newState.selectedCompanyForm };

  switch (type) {
    case UPDATE_FIELD_ATTRIBUTE:
      const { fieldName, fieldValue } = value;
      newFormCompanyObject[fieldName] = fieldValue;
      newState.selectedCompanyForm = newFormCompanyObject;
      // newState.startWidget = false;

      return {
        ...newState
      };

    case SEND_RESPONSE:

      return {
        ...newState
      };

    case START_CONVERSATION:
      newState.dataConversation = false;
      newState.idConversation = false;
      newState.creandoConversacion = true;


      return {
        ...newState
      }

    case START_CONVERSATION_SUCCESS:
      /* console.log('constants.action', action); */
      newState.esperandoConv = false;
      newState.idConversation = payload.conversationId;
      newState.selectedCompanyForm = {};
      newState.creandoConversacion = false;
      

      return {
        ...newState
      }
    case LISTENER_RESPONSE_SUCCESS:
      newState.dataConversation = payload;

      return {
        ...newState
      }

    case PLAY_SOUND:
      newState.playSound = payload.play;

      return {
        ...newState
      }

    case INICIAR_CHAT_ABIERTO:

      newState.iniciaChatAbierto = true;
      return {
        ...newState
      }

    case CAMBIAR_ESTADO_CHAT_ABIERTO:
      newState.iniciaChatAbierto = false;

      return {
        ...newState
      }

    case FORM_ENCUESTA_SUCCESS:
      newState.esperandoConv = payload.esperandoNuevaConv;
      newState.idConversation = payload.idConv;
      newState.dataConversation = payload.convData;
      newState.flagNewChat = payload.flag;
      newState.startWidget = false;

      return {
        ...newState
      }
    case ENCUESTA_CONF_SUCCESS:

      newState.infoEncuesta = payload;


      return {
        ...newState
      }


    case HANDLER_FILE_MESSAGE:
      newState.uploading = true;

      return {
        ...newState
      }

    case HANDLER_FILE_MESSAGE_SUCCESS:
      newState.uploading = false;
      newState.sendingFlow = false;

      return {
        ...newState
      }

    case FORM_ENCUESTA:

      return {
        ...newState
      }

    case START_WIDGET_SUCCES:
      newState.company = payload.company;
      newState.horario = payload.horario;
      newState.company_id = payload.company_id;
      newState.infoFormClient = payload.formularioInicial;
      newState.infoEncuesta = payload.encuestaConf;
      newState.enableFormClient = payload.isEnabledClientForm;
      newState.botEnabled = payload.botEnabled;
      newState.startWidget = true;
      newState.isEnabledCaptchaValidation = payload.isEnabledCaptchaValidation;
      newState.chatOpened = payload.chatOpened;
      newState.whatsAppMobileWeb =payload.whatsAppMobileWeb;
      newState.numWhatsApp = payload.numWhatsApp;

      return {
        ...newState
      }

    case SHOW_WIDGET:
      newState.showWidget = true;
      return {
        ...newState
      }

    case CHANGE_FLAG_WIDGET:
      newState.showWidget = false;
      return {
        ...newState
      }

    case SENDING_MSG_DF:
      newState.sendingFlow = true;
      return {
        ...newState
      }

    default:
      return {
        ...newState
      };
  }
};

export default reducer;
