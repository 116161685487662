import React, { Component } from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Collapse,
    TablePagination,
    Box
} from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faChevronUp, faChevronDown, faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import CustomButton  from '../custombutton/button';
import './table.css';
import CustomField from '../customform/customfield';
import { Link } from 'react-router-dom';


export default class CustomTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: [],
            ordered: []
        }
    }

    setOrder = (column, items, sense) => {
        const { ordered } = this.state;
        const { orderTable } = this.props;

        ordered[column] = !ordered[column];

        this.setState({
            ordered: ordered
        })

        orderTable ({ column, items, sense });
    }

    setOpen = (position) => {
        const { open } = this.state;
        const { items, subObject } = this.props;
        const subRow = items[position];

        if(open[position]) {
            open[position] = false;
        } else {
            open[position] = subRow[subObject];
        }
        this.setState({
            open: open
        })
    }
    
    render() {
        const { 
            items, 
            headers, 
            className, 
            onSelectRow, 
            buttons, 
            subObject, 
            subLabel, 
            childObject,
            childLabel,
            orderTable,
            filterTable,
            totalItems,
            paginator
        } = this.props;
        const { open, ordered } = this.state;

        return (
            <div className="custom-table_wrapper">
                <TableContainer className="custom-table_container" component={Paper}>
                { 
                    filterTable && 
                    <CustomField
                        id="search-001"
                        type="text"
                        typeForm="filter"
                        placeholder="Filtrar"
                        updateAction={filterTable}
                        name={"filter"}
                        items={totalItems}
                    />
                }
                <Table className={className} striped="true" bordered="true" hover="true" responsive="true"> 
                    <TableHead className="custom-table_head">
                        <TableRow className="custom-table_header">
                            {  
                                headers && headers.map((head, i) => {
                                return  (
                                        <TableCell key={i}>
                                            {
                                                orderTable && 
                                                    <React.Fragment>
                                                        { ordered[head.column] ?                                             
                                                            <FontAwesomeIcon 
                                                                onClick={() => this.setOrder(head.column, items, -1)} 
                                                                className='table-icon' 
                                                                icon={faArrowUp} /> :
                                                            <FontAwesomeIcon 
                                                                onClick={() => this.setOrder(head.column, items, 1)} 
                                                                className='table-icon' 
                                                                icon={faArrowDown} />
                                                        }
                                                    </React.Fragment>
                                            }
                                            <span className="header-title">{head.label}</span>
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        items && items.map((item, j) => {
                            return (
                                <React.Fragment key={`frag-${j}`}>
                                <TableRow className="custom-table_rows" key={j}>
                                    {   subObject && 
                                        <TableCell onClick={() => this.setOpen(j)}>
                                            { open[j] ?
                                                <FontAwesomeIcon className='table-icon' icon={faChevronUp} /> :
                                                <FontAwesomeIcon className='table-icon' icon={faChevronDown} />
                                            }
                                        </TableCell>
                                    }
                                    {
                                        item && Object.keys(item).map(e => {
                                            let tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>{item[e]}</TableCell>
                                            if (item[e] === true) {
                                                tableCell = 
                                                    <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                        <FontAwesomeIcon className='table-icon' icon={faThumbsUp} />
                                                    </TableCell>;
                                            } else if (item[e] === false) {
                                                tableCell = 
                                                    <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                        <FontAwesomeIcon className='table-icon' icon={faThumbsDown} />
                                                    </TableCell>;
                                            } else if (item[e] && typeof item[e] === 'object') {
                                                tableCell = "";
                                            }
                                            return (tableCell);
                                        })
                                    }
                                </TableRow>
                                { subObject &&
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={open[j]} timeout="auto" unmountOnExit>
                                                {
                                                    open[j] && open[j].length ? 
                                                    <Box className="custom-table_subtable">
                                                    <h3 className="sub-object_title">
                                                        <Link to={`/${subObject}`} className='table-link-title '>
                                                            {subLabel}
                                                        </Link>
                                                    </h3>
                                                    <CustomTable 
                                                        onSelectRow={false} 
                                                        className={`sub-object-${subObject}`} 
                                                        items={open[j]}
                                                        buttons={[]} 
                                                        subObject={childObject}
                                                        subLabel={childLabel}
                                                        />
                                                    </Box> : ''
                                                }
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                }
                                </React.Fragment>
                            );
                        })
                    }
                    </TableBody>
                </Table>
                </TableContainer>
                { paginator ? 
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={3}
                        rowsPerPage={2}
                        page={1}
                    />
                : '' }

                <CustomButton items={buttons} />
            </div>
        );
    }
}
