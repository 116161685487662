import {
    SEND_RESPONSE,
    START_CONVERSATION_SUCCESS,
    LISTENER_RESPONSE,
    LISTENER_RESPONSE_SUCCESS,
    LISTENER_RESPONSE_ERROR,
    FORM_ENCUESTA,
    FORM_ENCUESTA_SUCCESS,
    FORM_ENCUESTA_ERROR,
    START_WIDGET, START_WIDGET_ERROR, START_WIDGET_SUCCES, PLAY_SOUND, ENCUESTA_CONF, ENCUESTA_CONF_SUCCESS, 
    SHOW_WIDGET,
    SHOW_WIDGET_SUCCESS,
    CHANGE_FLAG_WIDGET,
    HANDLER_FILE_MESSAGE_SUCCESS,
    PLAY_SOUND_OFF,
    SENDING_MSG_DF,
    INICIAR_CHAT_ABIERTO,
    CAMBIAR_ESTADO_CHAT_ABIERTO
} from './constants'

export function updateAttributes(payload) {
    return {
        type: SEND_RESPONSE,
        payload
    };
}

export function startConversationSuccess(payload) {
    return {
        type: START_CONVERSATION_SUCCESS,
        payload
    };
}

export function listenerConversation(payload) {
    return {
        type: LISTENER_RESPONSE,
        payload
    };
}




export function listenerConversationSuccess(payload) {
    return {
        type: LISTENER_RESPONSE_SUCCESS,
        payload
    };
}

export function listenerConversationError(payload) {
    return {
        type: LISTENER_RESPONSE_ERROR,
        payload
    };
}

export function enviarEncuesta(payload) {
    return {
        type: FORM_ENCUESTA,
        payload
    }
}
 export function enviarEncuestaSuccess(payload) {
    return {
        type: FORM_ENCUESTA_SUCCESS,
        payload
    }
} 
 export function enviarEncuestaError(payload) {
    return {
        type: FORM_ENCUESTA_ERROR,
        payload
    }
} 


export function startWidget(payload) {
    return {
        type: START_WIDGET,
        payload
    }
} 

export function startWidgetError(payload) {
    return {
        type: START_WIDGET_ERROR,
        payload
    }
} 

export function startWidgetSucces(payload) {
    return {
        type: START_WIDGET_SUCCES,
        payload
    }
} 

export function playSound(payload) {
    return {
        type: PLAY_SOUND,
        payload
    }
} 


export function obtenerInfoConfEncuesta(payload){
    return{
        type:ENCUESTA_CONF,
        payload
    }
}

export function obtenerInfoConfEncuestaSuccess(payload){
    return{
        type:ENCUESTA_CONF_SUCCESS,
        payload
    }
}

export function mostrarWidget(payload){
    return{
        type: SHOW_WIDGET,
        payload
    }
}


export function changeFlagShowWidget(payload){
    return{
        type:CHANGE_FLAG_WIDGET,
        payload
    }
}

export function sendResponseAction(payload){
    return{
        type: SEND_RESPONSE,
        payload
    }
}

export function handleFileMessageSuccess(payload){
    return{
        type: HANDLER_FILE_MESSAGE_SUCCESS,
        payload
    }
}

export function sendingMessageDialogFlow(payload){
    return{
        type: SENDING_MSG_DF,
        payload
    }
}

export function iniciarChatAbierto(payload){
    return{
        type:INICIAR_CHAT_ABIERTO,
        payload
    }
}

export function cambiarEstadoChatAbierto(payload){
    return{
        type:CAMBIAR_ESTADO_CHAT_ABIERTO,
        payload
    }

}
