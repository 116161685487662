import { firebaseDatabase } from "./firebase";

export function* fijarDatosEncuesta(companyId, idConv, estado, bodyEncuesta) {
    try {
        console.log("bodyEncuesta***", bodyEncuesta);
        firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConv).update({
            estado: estado,
            encuesta: bodyEncuesta,
        });
        /* firebaseDatabase.collection(`company/${companyId}/conversations`).doc(idConv) */


    } catch (error) {
        console.error('controller.conversation.fijarDatosEncuesta', error);
        throw error
    }

}

export function* actualizarConnectedUser(companyId,idConv) {
    let idDocAgente;
    try {
        firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConv).get()
        .then(conv=>{
             idDocAgente = conv.data().agente && conv.data().agente.idDoc ? conv.data().agente.idDoc:false;
             if(idDocAgente){
             firebaseDatabase.collection('company').doc(companyId).collection('connectedUsers').doc(idDocAgente).update({
                 last_survey: new Date()
             })
            }
        })

    } catch (error) {
        console.error('controller.conversation.actualizarConnectedUser', error);
        throw error
    }
}

/* export function* fijarFechaUltimaEncuesta(idDoc){




} */