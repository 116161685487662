import { takeLatest, put, call, take, takeEvery } from "redux-saga/effects";
import { addResponseMessage, addUserMessage, renderCustomComponent, dropMessages, addLinkSnippet } from 'react-chat-widget';
import Lightbox from "react-awesome-lightbox";
import CustomVideoPlayer from './videoplayer';
import ReplyComponent from './reply';
import AudioPlayer from 'react-h5-audio-player';
import { Image } from 'react-bootstrap';

import { isMobile, isDesktop, isOpera, isChrome, isFirefox, isEdge, isTablet } from 'react-device-detect';
import {
  SEND_RESPONSE,
  LISTENER_RESPONSE,
  TEXT_RESPONSE,
  START_CONVERSATION,
  FORM_ENCUESTA, START_WIDGET, ENCUESTA_CONF, HANDLER_FILE_MESSAGE

} from './constants';

import { firebaseDatabase, firebaseField, firebaseStorage, firebaseAuthentication } from "../../controller/firebase";
import { getFromSession, removeItems, storeInSession } from "../../controller/session";
import { eventChannel } from "redux-saga";
import { consultarEmpresa } from "../../controller/company"

import {

  listenerConversationSuccess,
  listenerConversationError,
  listenerConversation,
  startConversationSuccess,
  enviarEncuestaError,
  enviarEncuestaSuccess,
  startWidgetSucces, startWidgetError, playSound, obtenerInfoConfEncuestaSuccess, handleFileMessageSuccess, sendingMessageDialogFlow

} from "./actions";

import { fijarDatosEncuesta, actualizarConnectedUser } from "../../controller/conversation"
import { consultaProperty, consultaPropertyAll, validarHorarioHabil } from "../../controller/configuration";
import { mensajeDialogflow } from "../../controller/dialogflow";
import { RichText } from "./components";

function* sendResponse(action) {

  const companyId = getFromSession('company_id');
  const { idConversartion } = getFromSession('idConversartion');
  const clienteData = getFromSession('clienteData');

  const { value: { client, reply, fileMessage, conversationData: conv, newMessage: value } } = action;

  const valueString = value.toString();

  if (reply) {

    yield addUserMessage(valueString);
  }

  let msge = {
    fecha: firebaseField.serverTimestamp(),
    from: {
      tipo: "cliente",
      cliente: clienteData ? clienteData : {
        nombre: "cliente prueba",
        mail: "mail prueba"
      }
    },
    mensaje: {
      text: fileMessage ? false : valueString,
      type: fileMessage ? fileMessage.type : 'text',
      caption: fileMessage ? fileMessage.caption : '',
      title: fileMessage ? fileMessage.title : '',
      filename: fileMessage ? fileMessage.filename : '',
      url: fileMessage ? fileMessage.url : '',
    }
  };
  firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConversartion).collection('mensajes').add(msge);

  let upd = {
    last_message: firebaseField.serverTimestamp()
  }
  upd['search'] = firebaseField.arrayUnion(valueString.trim());

  firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConversartion).update(
    upd
  );

  /**
   * Modificacion para incluir comunicacion con chatbot de dialogflow:
   * 1. se valida si se tiene habilitado Chatbot con propiedad "bot_enabled" de configuracion, y elnombre del agente es "Chatbot"
   * 2. se hace la consulta a dialogFlow
   * 3. se agrega la respuesta a la conversacion
  */

  //nuevos cambios, se agrega un cambio que dice la posicion donde se halla el chatbot en una pagina
  const multiplesHijos = yield consultaProperty(companyId, 'MULTIPLES_HIJOS');
  let param_Hijo = false;
  if (multiplesHijos) {
    param_Hijo = "hijo1";
  }


  const botEnabled = yield consultaProperty(companyId, 'BOT_ENABLED');
  if (botEnabled && conv.agente.nombres === 'Chatbot') {
    yield put(sendingMessageDialogFlow(action));
    yield mensajeDialogflow(companyId, idConversartion, msge.mensaje, param_Hijo);
  }


  if (client) {
    //console.log('addCustomMessage.SAGA.SENDRESPONSE');
    yield addCustomMessage(fileMessage, client);
  }

  yield put(handleFileMessageSuccess(action));
}

function parseMarkdown(markdownText) {
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
    .replace(/\*(.*)\*/gim, '<i>$1</i>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')

  return htmlText.trim()
}

function* listenerResponse(idConv) {

  const { idConversartion } = getFromSession('idConversartion');
  const companyId = getFromSession('company_id');
  const clientFormEnabled = getFromSession('clientFormEnabled');
  const reference = firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConversartion).collection('mensajes').orderBy('fecha', 'asc');
  const channel = eventChannel(emit => reference.onSnapshot(emit));

  try {
    let inited = false;
    while (true) {
      let lastElement = false;
      const realData = yield take(channel);
      let nuevoMsge = false;
      if (!inited) {
        //la primera vez se carga todo:
        realData.docs.forEach(doc => {
          lastElement = doc.data();


          if (lastElement.from.tipo === 'agente') {

            //console.log('addCustomMessage.Agente => ', lastElement);
            nuevoMsge = true;
            addCustomMessage(lastElement.mensaje);
          } else {
            if (idConv.value || clientFormEnabled) {
              addCustomMessage(lastElement.mensaje, true);
            }

            //console.log('addCustomMessage.else.agente.TEXT => ', lastElement);
            //addCustomMessage(lastElement.mensaje, true);
          }

        });
      } else {
        nuevoMsge = false;
        realData.docChanges().forEach(change => {
          lastElement = change.doc.data();

          if (lastElement.from.tipo === 'agente') {
            nuevoMsge = true;
            //console.log('addCustomMessage.nuevoMsge.TEXT => ', lastElement);
            addCustomMessage(lastElement.mensaje);
          }
        });
      }
      if (nuevoMsge) {
        //console.log('nuevo Msge if', nuevoMsge)
        yield put(playSound({ play: true }));
      }
      inited = true;
      realData.docChanges().forEach(change => {
        lastElement = change.doc.data();
      });
      //lastElement ya tiene el ultimo mensaje del agente
    }
  }
  catch (e) {
    console.error(e);
  }
}

function addCustomMessage(mensaje, client) {

  if (mensaje.type === 'text' && !client) {
    /* console.log('addCustomMessage.mensaje.text', mensaje); */
    renderCustomComponent(
      RichText,
      {
        className: 'rcw-message-text ',
        text: mensaje.text
      },
      !client
    );
  } else if (mensaje.type === 'text' && client) {

    addUserMessage(mensaje.text);
  }
  else if (mensaje.type === 'image' || mensaje.type === 'sticker') {
    if (client) {
      renderCustomComponent(
        Image,
        {
          className: `image-mesage ${client ? 'rcw-client client-message' : ''}`,
          src: mensaje.url,
          alt: mensaje.caption
        },
        !client
      );
    } else {
      renderCustomComponent(
        Lightbox,
        {
          className: `image-mesage ${client ? 'rcw-client client-message' : ''}`,
          image: mensaje.url,
          alt: mensaje.caption,
          doubleClickZoom: 4
        },
        !client
      );
    }
  } else if (mensaje.type === 'video') {
    renderCustomComponent(
      CustomVideoPlayer,
      {
        className: `video-mesage ${client ? 'rcw-client client-message' : ''}`,
        video: mensaje.url,
        title: mensaje.caption
      },
      !client
    );
  } else if (mensaje.type === 'audio') {
    renderCustomComponent(
      AudioPlayer,
      {
        className: `audio-mesage ${client ? 'rcw-client client-message' : ''}`,
        src: mensaje.url,
        autoPlay: false,
      },
      !client
    );
  } else if (mensaje.type === 'quick_reply') {
    renderCustomComponent(
      ReplyComponent,
      {
        className: `quick-message ${client ? 'rcw-client client-message' : ''}`,
        options: mensaje.options,
        title: mensaje.title,
        subtitle: mensaje.subtitle,
        type: mensaje.type
      },
      !client
    );
  } else if (mensaje.type === 'list') {
    renderCustomComponent(
      ReplyComponent,
      {
        className: `list-message ${client ? 'rcw-client client-message' : ''}`,
        options: mensaje.options,
        title: mensaje.title,
        subtitle: mensaje.body,
        type: mensaje.type,
        items: mensaje.items,
        image: mensaje.url
      },
      !client
    );
  } else if (mensaje.type === 'location') {
    renderCustomComponent(
      'a',
      {
        className: `link-message rcw-message-text`,
        title: mensaje.name,
        href: 'https://maps.google.com/maps/search/' + mensaje.name + '/@' + mensaje.latitude + ',' + mensaje.longitude + ',17z?hl=es',
        target: '_blank',
        children: mensaje.name
      },
      !client
    );
  } else {
    if (client) {
      renderCustomComponent(
        'a',
        {
          className: `link-message rcw-client client-message`,
          title: mensaje.filename,
          href: mensaje.url,
          target: '_blank',
          children: mensaje.filename
        },
        false
      );
    } else {
      addLinkSnippet({
        className: `link-mesage`,
        title: mensaje.filename,
        link: mensaje.url,
        target: '_blank'
      })
    }
  }
}


function* listenerConversationInfo() {

  let { idConversartion } = getFromSession('idConversartion');
  const companyId = getFromSession('company_id');

  const referenceConv = firebaseDatabase.collection('company').doc(companyId).collection('conversations').doc(idConversartion);

  const channelConv = eventChannel(emit => referenceConv.onSnapshot(emit));

  let conversation = false;

  try {
    while (true) {

      const realData = yield take(channelConv);
      conversation = realData.data();

      if (idConversartion) {
        yield put(listenerConversationSuccess(conversation));

      }
    }
  } catch (err) {
    console.error('saga.listenerConversationInfo:' + err);
    put(listenerConversationError(err));
    return;
  }

}

function* obtenerInfoConfEncuesta(action) {
  try {

    const companyId = getFromSession('company_id');
    let preguntasEncuesta = yield consultaProperty(companyId, 'ENCUESTA');

    yield put(obtenerInfoConfEncuestaSuccess(preguntasEncuesta));
  } catch (error) {
    console.error('saga.obtenerInfoConfEncuesta', error);
    throw error
  }
}



function* enviarEncuesta(action) {
  try {
    const { idConversartion } = getFromSession('idConversartion');
    const companyId = getFromSession('company_id');
    //console.log('action.value', action.value);
    let datosEncuesta = action.value ? action.value : false;
    let estado = 11;
    const limpiarChat = yield consultaProperty(companyId, "LIMPIAR_MENSAJES_CLIENTEWEB")

    if (datosEncuesta) {
      yield fijarDatosEncuesta(companyId, idConversartion, estado, datosEncuesta);

      yield actualizarConnectedUser(companyId, idConversartion);
    }


    if (limpiarChat) {
      dropMessages();
    }
    removeItems('clienteData');
    removeItems('idConversartion');

    yield put(enviarEncuestaSuccess({ idConv: false, esperandoNuevaConv: true, convData: false, flag: true }))

  } catch (err) {
    console.error('saga.enviarEncuesta', err);
    put(enviarEncuestaError(err));
    return;
  }
}


function* textResponse() {
  const conversationData = { nombre: "asdf", mail: "asdf", motivo: "asdf" }
}

function* startConversation(payload) {

  let clienteData = { ...payload.value };
  const companyId = getFromSession('company_id');
  let isEnabledCaptchaValidation = yield consultaProperty(companyId, 'CAPTCHA_ENABLED');
  let isEnabledClientForm = yield consultaProperty(companyId, 'CLIENT_FORM_ENABLED');

  let textButtonReply = false
  if (clienteData.newMessage) {
    //se valida si la conversacion empieza por quick replies
    yield addUserMessage(clienteData.newMessage);
    textButtonReply = clienteData.newMessage
  }


  addResponseMessage("Iniciando conversación...");


  let clienteBody;
  let tipoDispositivo = isMobile ? 'Mobile' : isDesktop ? 'Desktop' : isTablet ? 'Tablet' : 'No reconocido';
  let navegador = isChrome ? 'Chrome' : isEdge ? 'Edge' : isFirefox ? 'Firefox' : isOpera ? 'Opera' : 'Sin identificar';

  //nuevos cambios, se agrega un cambio que dice la posicion de
  //TODO: Consultar properti HIJos
  const multiplesHijos = yield consultaProperty(companyId, 'MULTIPLES_HIJOS');
  let param_Hijo = false;
  if (multiplesHijos) {
    param_Hijo = "hijo1"//getFromSession('param_Hijo');
  }
  let sitio = window.location.href;
  let valorCaptcha;

  if (isEnabledCaptchaValidation) {
    valorCaptcha = payload.value.captcha ? payload.value.captcha : "No validado"
  } else {
    valorCaptcha = "No aplica"
  }

  if (Object.keys(clienteData).indexOf('nombre') !== -1) {
    clienteBody = {
      param_Hijo,
      company: companyId,
      nombre: clienteData.nombre,
      mail: clienteData.mail,
      motivo: clienteData.subject,
      device: 'dispositivo: ' + tipoDispositivo + ', navegador: ' + navegador,
      sitio: sitio
    }
  } else {
    let motivo = textButtonReply ? textButtonReply : payload.value.subject ? payload.value.subject : payload.value
    clienteBody = {
      param_Hijo,
      company: companyId,
      nombre: 'anonimo',
      mail: 'anonimo@anonimo.com',
      motivo: motivo,
      device: 'dispositivo: ' + tipoDispositivo + ', navegador: ' + navegador,
      captchaValidado: valorCaptcha,
      sitio: sitio
    }

    clienteData = {
      nombre: 'anonimo',
      mail: 'anonimo@anonimo.com',
      subject: motivo,
    }
  }
  let idConversartion = false;
  const urlConv = process.env.REACT_APP_NEW_CONVERSATION_ENDPOINT;
  const response = yield call(fetch, urlConv, {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'cache-control': 'no-cache',
    },
    body: JSON.stringify(clienteBody)
  });

  if (response.status >= 200 && response.status < 300) {
    const successResponse = yield call([response, response.json]);
    idConversartion = successResponse.conv;
  } else {
    const errorResponse = yield call([response, response.json]);
    console.error('errorResponse', errorResponse);
  }

  if (idConversartion) {
    storeInSession('idConversartion', JSON.stringify({ idConversartion }));
    storeInSession('clienteData', JSON.stringify(clienteData));

    try {
      yield put(listenerConversation());
      yield put(startConversationSuccess({ conversationId: idConversartion }));
    } catch (error) {
      console.error(error);
    }
  }
}

/**FUNCION PARA INICIAR EL WIDGET:
 * debe recibir el id de la empresa y obtener el logo, nombre del cliente, horario
 */
function* startWidget(payload) {
  try {
    //recibe la instancia Hijo si la hay, y a que correspondencia se encuentra
    let param_Hijo = false;
    if (payload.value?.hijo)
      param_Hijo = payload.value.hijo;
    let company_id = payload.value.company;
    let company = yield consultarEmpresa(company_id);
    let horario = yield validarHorarioHabil(company.id);
    let botEnabled = yield consultaProperty(company.id, 'BOT_ENABLED');
    const whatsAppMobileWeb = yield consultaProperty(company.id, 'WHATSAPP_MOBILE_WEB')
    const numWhatsApp = yield consultaProperty(company.id, 'TELEFONO_WHATSAPP')

    /*  company['whatsappMobile'] =whatsAppMobileWeb?whatsAppMobileWeb:false */

    //Si el bot esta habilitado, el horario siempre sera valido:
    if (botEnabled) {
      horario.valido = true;
    }
    let formularioInicial = false;

    storeInSession('company_id', JSON.stringify(company.id));
    storeInSession('param_Hijo', JSON.stringify(param_Hijo));

    let isEnabledClientForm = yield consultaProperty(company.id, 'CLIENT_FORM_ENABLED');
    let isEnabledCaptchaValidation = yield consultaProperty(company.id, 'CAPTCHA_ENABLED');
    let chatOpened = yield consultaProperty(company.id, "INICIAR_CHAT_ABIERTO")

    storeInSession('clientFormEnabled', JSON.stringify(isEnabledClientForm));

    if (isEnabledClientForm) {
      //Si el formulario inicial esta habilitado entonces se consulta la propiedad donde estan 
      //los campos del formulario
      formularioInicial = yield consultaProperty(company.id, 'CLIENT_FORM');
    } else {
      //si no esta el formulario inicial habilitado entonces se muestra un mensaje de bienvenida
      let propiedadesMensajeBienvenida = yield consultaPropertyAll(company.id, 'MENSAJE_BIENVENIDA_CLIENTE_WEB');
      let mensajeBienvenida = propiedadesMensajeBienvenida.value;
      let tipoMensajeBienvenida = propiedadesMensajeBienvenida.tipo;

      storeInSession('tipoMensajeBienvenida', JSON.stringify(tipoMensajeBienvenida));
      if (mensajeBienvenida) {
        if (tipoMensajeBienvenida === 'texto') {
          //se añade un texto plano 
          addResponseMessage(mensajeBienvenida);
        } else if (tipoMensajeBienvenida === 'quick_reply') {
          //se añaden quick replies, es una lista porque primero es un mensaje plano y 
          //luego un mensaje con los quick replies
          mensajeBienvenida.forEach(texto => {
            let mensaje = JSON.parse(texto);
            // console.log("JSON PARSE", mensaje);
            addCustomMessage(mensaje)
          });
        }
      }

    }
    let encuestaConf = yield consultaProperty(company.id, 'ENCUESTA');
    yield put(startWidgetSucces({
      company: company.data(), horario, company_id: company.id,
      formularioInicial, encuestaConf, isEnabledClientForm, botEnabled, isEnabledCaptchaValidation,
      chatOpened, whatsAppMobileWeb, numWhatsApp
    }));
  } catch (error) {
    console.error('saga.cliente.startWidget', error)
    startWidgetError({ error })

  }
}



function* handlerFileMessage(action) {

  const company = getFromSession('company_id');
  const { idConversartion } = getFromSession('idConversartion');
  const today = new Date();
  const timestampDate = firebaseField.serverTimestamp();

  let archivos = action.value
  let isVoiceRecord = action.value.isVoiceRecord ? true : false;
  addResponseMessage("Adjuntando archivo...");

  //console.log('AUDIO DATA', isVoiceRecord, archivos);
  let convData = false;
  yield firebaseDatabase.collection('company').doc(company).collection('conversations').doc(idConversartion).get().then(d => {
    convData = d.data();
  })


  let fileParameters = false;
  yield firebaseAuthentication.signInAnonymously();
  fileParameters = yield armarJSONparametros(company, idConversartion, archivos, timestampDate);


  const actionResponse = {
    value: {
      newMessage: fileParameters[0].url,
      conversationData: {
        agente: {
          nombres: convData.agente.nombres
        }
      },
      client: true,
      fileMessage: {
        caption: fileParameters[0].nombre,
        title: fileParameters[0].nombre,
        filename: fileParameters[0].nombre,
        url: fileParameters[0].url,
        type: fileParameters[0].tipo
      }
    }
  }

  yield sendResponse(actionResponse);
  yield put(playSound({ play: true }));
}

async function armarJSONparametros(company, idConversation, archivos, timestampDate) {
  //Creamos una función asincrona cuyas tareas asincronas las vamos a sincronizar
  //debugger;

  let parametros = [];
  for (const archivo of archivos) {

    let nombreArchivo = !archivo.name ? `${timestampDate}_${"voiceRecording.wav"}` : archivo.name
    let ref = `${company}/conversations/${idConversation}/${nombreArchivo}`;
    const storageRef = firebaseStorage.ref(ref);
    let typeArchivo = archivo.blob ? archivo.blob.type : archivo.type
    let tipo = typeArchivo.split(["/"])[0];
    let diccionarioTiposArchivo = {
      "application": "file",
      "image": "image",
      "audio": "audio",
      "video": "video",
    }
    let tipoArchivo = diccionarioTiposArchivo[tipo];
    tipoArchivo = !tipoArchivo ? "file" : tipoArchivo;

    let archivo_ = archivo.blob ? archivo.blob : archivo

    //console.log('ARCvhivvovo', archivo_);

    //primera tarea asincrona, usamos await para esperar a que se resuelva la promesa
    const task = await storageRef.put(archivo_).then(async result => {
      //segunta tarea asincrona, usamos el async luego el await para esperar a que esta promesa se resuelva
      parametros.push({
        idConversation: idConversation,
        tipo: tipoArchivo,
        nombre: nombreArchivo,
        url: await storageRef.getDownloadURL()
      })
    });
  }
  return parametros;
}

export function* watchUsers() {
  yield takeLatest(SEND_RESPONSE, sendResponse);
  yield takeEvery(LISTENER_RESPONSE, listenerResponse);
  yield takeLatest(LISTENER_RESPONSE, listenerConversationInfo);
  yield takeLatest(TEXT_RESPONSE, textResponse);
  yield takeLatest(START_CONVERSATION, startConversation);
  yield takeLatest(FORM_ENCUESTA, enviarEncuesta);
  yield takeLatest(START_WIDGET, startWidget);
  yield takeLatest(ENCUESTA_CONF, obtenerInfoConfEncuesta);
  yield takeLatest(HANDLER_FILE_MESSAGE, handlerFileMessage);
}
