import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Image } from 'react-bootstrap';
/* import { Button } from 'primereact/button'; */
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import { LoadCanvasTemplate } from 'react-simple-captcha';
/* import { InputText } from 'primereact/inputtext'; */
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


export class RichText extends Component {
    render() {
        const { text } = this.props;
        let richtext = text ? text.replaceAll(/\*/ig, '**').replaceAll(/\n+/ig, '\n\n') : '';

        return (
            <div className='richtext-message'>
                <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank" children={richtext} />
            </div>
        );
    }
}

export class CustomSpinner extends Component {
    render() {
        const { id, text, type, className, spinnerClassName, textClassName, color } = this.props;

        return (
            <div className={`modal-container-${className}`}>
                <div id={id} className={className}>
                    {
                        type === 'linear' ? <LinearProgress  className={spinnerClassName} color={color} /> :
                            <CircularProgress className={spinnerClassName} color={color} />
                    }
                    {text && <p className={textClassName}> {text} </p>}
                </div>
            </div>
        );
    }
}

export class CustomCaptcha extends Component {
    render() {
        const { subtitle, logo, reloadText, placeholder, renderThis, svgIconX } = this.props;

        return (
            <div className="captcha-container">
                <div className='form-title'>
                    <Image style={{ maxWidth: "51%" }} className='image-title' src={logo}></Image>
                    <ForumOutlinedIcon className="icon-title" />
                </div>
                <p className="form-sub-title">
                    {subtitle}
                </p>

                <div className="captchaForm">
                    <div className="flex align-items-center justify-content-center ">
                        <LoadCanvasTemplate
                            reloadText={reloadText} />
                    </div>
                    <div className="flex align-items-center justify-content-center mt-2">
                        {/* <InputText style={{ "width": "13rem" }} placeholder={placeholder} value={renderThis.state.valueCaptcha} onInput={(e) => renderThis.setState({ valueCaptcha: e.target.value })} /> */}
                        <TextField sx={{ "width": "13rem" }} id="outlined-basic" label={placeholder} variant="outlined" value={renderThis.state.valueCaptcha} onInput={(e) => renderThis.setState({ valueCaptcha: e.target.value })}/>
                    </div>
                    {renderThis.state.errorCaptchaInvalido ?
                        <div className="flex align-items-center justify-content-center">
                            <p style={{ "color": "red", "paddingTop": "0px" }} className="form-sub-title" >Caracteres incorrectos, intente de nuevo.</p>
                        </div>
                        : false}
                    <div className="flex align-items-center justify-content-center mt-3">
                        {/* <Button className="p-button-info" label="Enviar" onClick={() => renderThis.doSubmit(renderThis.state.valueCaptcha)}></Button> */}
                        <Button color="secondary" variant="contained" onClick={() => renderThis.doSubmit(renderThis.state.valueCaptcha)}>Enviar</Button>
                    </div>
                </div>
                <React.Fragment>
                    <div className="fake-field"></div>
                    <div className="fake-button close">
                        <button className="rcw-close-button rcw-fake-close-button">
                            <img src={svgIconX} className="rcw-close" alt="close" />
                        </button>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

